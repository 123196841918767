import React, { useState } from 'react';
import './Modal.css'; 

const Modal = ({ isOpen, onClose, data }) => {
  return (
    <div className={`modal ${isOpen ? 'open' : ''}`}>
      <div className="modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        <div id="modalBody" dangerouslySetInnerHTML={{ __html: (data) }} />
      </div>
    </div>
  );
};

export default Modal;
