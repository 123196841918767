import React from "react";
import "./Footer.css"; 

function Footer() {
  return (
    <footer className="footer">
      <div className="left-content">
        ©2023 complieaze, All rights reserved. A data product by 
        <a href="https://www.dataeaze.io/" target="_blank" rel="noopener noreferrer">
        <img src="dataease-logo.png" alt="Product Image" className="bottom-left-image" />
        </a>
      </div>
      
      <a href="https://aws.amazon.com/what-is-cloud-computing" target="_blank" rel="noopener noreferrer">
    <img src="https://d0.awsstatic.com/logos/powered-by-aws.png" alt="Powered by AWS Cloud Computing" className="aws"/>
  </a>
      <div className="right-content">
        <span>Terms & Conditions</span> | <span>Privacy Policy</span>
      </div>
    </footer>
  );
}

export default Footer;
