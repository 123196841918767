import React, { useEffect, useState } from 'react';
import { GlobalWorkerOptions } from 'pdfjs-dist';
import { Document, Page } from 'react-pdf'; 
import './PdfModalPage.css'; 

GlobalWorkerOptions.workerSrc = '/pdf.worker.js';

const PdfModalPage = ({ pdfUrl, onClose }) => {
  const [numPages, setNumPages] = useState(null);
  const [pdfLoaded, setPdfLoaded] = useState(false);

  useEffect(() => {
    console.log('PDF URL:', pdfUrl); 
  }, [pdfUrl]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPdfLoaded(true); 
  };

  return (
    <div className="pdf-modal">
      <div className="pdf-modal-content">
        <span className="pdf-modal-close" onClick={onClose}>&times;</span>
        <div className="pdf-content">
          <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        </div>
      </div>
    </div>
  );
};

export default PdfModalPage;
