import React, { useState } from "react";
import "./MobileLanding_Page.css";
import domainExpertsImage from "../../assets/domainExperts.svg";
import endAutomationImage from "../../assets/endAutomation.svg";
import genaiInitativesImage from "../../assets/genaiInitatives.svg";
import NavbarComponent from "./Navbar";
import Footer from "./Footer";
import overloadImage from "../../assets/overloadImage.jpg";
import largeUnstructured from "../../assets/largeUnstructured.jpg";
import assistant from "../../assets/assistant.svg";
import consultant from "../../assets/consultant.svg";
import analyst from "../../assets/analyst.svg";

const cardData = [
  {
    id: 1,
    title: "Domain Experts",
    content:
      "Team with experience of building Modern AI solutions across industries. dataeaze is making it easy to work with data for its client since last 8 years. Has built data platforms across cloud platforms (aws, gcp, azure, on prem), with large volume of data.",
    image: domainExpertsImage,
  },
  {
    id: 2,
    title: "End to End Automation",
    content:
      "Team who have helped multiple large corporates with end to end data automation. Has built modern data platforms across domains telecom, adtech, fintech, healthcare, edutech etc.",
    image: endAutomationImage,
  },
  {
    id: 3,
    title: "GenAI Initiatives Across Domains",
    content:
      "Team working on multiple GenAI initiatives across domains. Has built data platforms across cloud platforms (aws, gcp, azure, on prem), with large volume of data",
    image: genaiInitativesImage,
  },
];

const MobileLandingPage = () => {
  const [activeCard, setActiveCard] = useState(1);
  const handleCardClick = (cardId) => {
    setActiveCard(cardId === activeCard ? null : cardId);
  };
  const handleDemoButtonClick = () => {
    window.location.href = "/login";
  };
  return (
    <div className="mobile-landing-page">
      <NavbarComponent />
      <section id="home" className="home-sectionMob">
        <h1 className="homeTextMob">
          A GenAI assistant makes working with regulatory compliance easy.
        </h1>
        <button className="demo-buttonApp1">Get a Demo</button>

        <div className="col-12 col-md-4  ">
          <div className="bg-small-container">
            <h4 className="home-section-heading">With bankereaze</h4>
            <p className="home-section-paragraph">
              Access and analyse thousands of RBI and SEBI compliance circulars
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 ">
          <div className="bg-small-container">
            <h4 className="home-section-heading">With bankereaze</h4>
            <p className="home-section-paragraph">
              Save time in search of regulatory compliance documents by 80%
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 ">
          <div className="bg-small-container">
            <h4 className="home-section-heading">With bankereaze</h4>
            <p className="home-section-paragraph">
              Access and analyse thousands of RBI and SEBI compliance circulars
            </p>
          </div>
        </div>
      </section>

      <section id="feature" className="feature-section featureMob">
        <div className="bg-container-feature-section">
          <h1 className="feature-heading">
            Working with Compliance Documents is{" "}
            <span className="complex">Complex</span>
          </h1>
          <div className="bg-work-overload">
            <div>
              <img src={overloadImage} className="overloadImage" />
            </div>

            <h1 className="overload-heading">
              <span className="overload-span">Work overloaded</span> compliance
              officer
            </h1>
            <p className="overload-paragraph">
              Vast and ever-changing nature regulations have always been a risk
              for businesses. <br /> <br />
              Compliance departments, consultants and experts are overloaded
              with information.
            </p>
            <img src={largeUnstructured} className="largeUnstructured" />
            <h1 className="overload-heading">
              Laarge unstructured document data,
              <span className="overload-span"> hard to auto analyse</span>{" "}
            </h1>
            <p className="overload-paragraph">
              With regulations the data is in the form of unstructuredtext,
              which made it a challenge for software systems to query and
              analyze.
            </p>
          </div>

          <div>
            <h1 className="overload-bankereaze-heading">
              bankereaze is built to simplify compliance
            </h1>
            <h2 className="overload-bankereaze-paragraph">
              With revolutionary advancements in GenAl, Foundation Models and
              NLP
            </h2>
          </div>
          <div className="bg-whiteColor">
            <div>
              <img src={assistant} className="assistant" />
              <h4 className="ass-heading">Assistant</h4>
              <p className="ass-paragraph">
                As a knowledge-assistant helps find information needed from a
                database of regulatory documents like acts, rules, regulations,
                master circulars, circular and notifications. The interface is
                chat based and the answers are supported by references.
              </p>
            </div>
            <div>
              <img src={consultant} className="assistant" />
              <h4 className="ass-heading">consultant</h4>
              <p className="ass-paragraph">
                The AI assists with, Alerts on impactful regulatory changes.
                Enlist the actions to be taken along with deadlines, Explain the
                regulatory change in the notification, Build a knowledge graph
                of the ever-changing rules to enable querying for applicability,
                impact, timelines of the rules
              </p>
            </div>
            <div>
              <img src={analyst} className="assistant" />
              <h4 className="ass-heading">analyst</h4>
              <p className="ass-paragraph">
                {" "}
                Help analyze the numbers periodically published by regulatory
                bodies like NAVs, indexes etc in a natural language interface.
                The system can generate plots and human readable summaries in
                addition to tabular output.{" "}
              </p>
            </div>
          </div>
          <div className="col-12 backgroundCurveMob">
            <div className="nextTextMob">
              <span>Ready to take the next step?</span>
              <br />
              <span>Explore bankereaze now.</span>
              <br />
            </div>
            <button
              className="demo-buttonFeatureMob"
              onClick={() => handleDemoButtonClick()}
            >
              Get Started
            </button>
          </div>
          <div className="bg-domainExperts">
            <h1 className="domainExperts-heading">
              bankereaze is built by data <br />
              experts at dataeaze <br />
              systems
            </h1>
          </div>
          <div className="col-12 contentWrapperMob">
            <div className="card-containerMob col-9">
              {cardData.map((card) => (
                <div
                  key={card.id}
                  className={`feature-cardMob ${
                    card.id === activeCard ? "active" : ""
                  }`}
                  onClick={() => handleCardClick(card.id)}
                >
                  <div className="card-content col-3">
                    <h2>{card.title}</h2>
                    {card.id === activeCard && <p>{card.content}</p>}
                  </div>
                </div>
              ))}
            </div>
            <div className="card-imageMob col-3">
              {cardData.map((card) => (
                <div
                  key={card.id}
                  className={`feature-card-image ${
                    card.id === activeCard ? "active" : ""
                  }`}
                >
                  {card.id === activeCard && (
                    <img src={card.image} alt={card.title} />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section id="pricing" className="pricing-sectionMob">
        <div className="bg-priceSection">
          <h1 className="priceSection-heading">
            We have custom solution as per your needs.
          </h1>
          <div className="priceSectionCard1">
            <h4 className="priceSectionCard-heading">Basic</h4>
            <div className="line">
              <span className="breakLine1"></span>
            </div>
            <p className="Assistant">Assistant</p>
            <div className="line">
              <span className="breakLine2"></span>
            </div>
            <p className="tokens">0.24 USD per 1K tokens </p>
            <p className="tokens">OR</p>
            <p className="tokens">0.00390 USD per request per </p>
            <p className="tokens">second</p>
          </div>

          <div className="priceSectionCard2">
            <h4 className="priceSectionCard-heading">Premium</h4>
            <div className="line">
              <span className="breakLine1"></span>
            </div>
            <p className="assi-premium">
              <span className="span">Consultant</span>
              <br />
              Analyst
              <br />
              Human
            </p>
            <div className="line">
              <span className="breakLine2"></span>
            </div>
            <p className="tokens">0.96 USD per 1K tokens </p>
            <p className="tokens">OR</p>
            <p className="tokens">0.01560 USD per request per </p>
            <p className="tokens"> per second</p>
          </div>

          <div className="priceSectionCard3">
            <h4 className="priceSectionCard-heading">Enterprise</h4>
            <div className="line">
              <span className="breakLine1"></span>
            </div>

            <p className="enterprise-heading">
              On-premise / virtual-private cloud
            </p>
            <div className="enterprise">
              <p className="">deployment</p>
              <p className="">Bring your own data</p>
              <p className="">Customisation to use cases</p>
            </div>
            <div className="line">
              <span className="breakLine2"></span>
            </div>
            <p className="tokens">Contact sales</p>
          </div>
        </div>
      </section>
      <section id="contact" className="contact-sectionMob">
        <div className="bg-form">
          <h1 className="get-banker-heading">Get bankereaze demo</h1>
          <p className="get-banker-paragraph">
            Fill out these details and we will get right back to you. <br />
            We are available 24/5, Monday - Friday
          </p>

          <div className="container">
            <form id="myForm">
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="   Your Name*"
                />
              </div>
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="   Contact Number*"
                />
              </div>
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="   Email ID*"
                />
              </div>
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="   Company Name*"
                />
              </div>
              <div>
                <select className="form-control-looking">
                  <option value="India">Select Country</option>
                  <option value="None">None</option>
                  <option value="India">India</option>
                  <option value="America">America</option>
                  <option value="Australia">Australia</option>
                  <option value="Bangladesh">Bangladesh</option>
                </select>
              </div>
              <div>
                <select className="form-control-looking">
                  <option value="Looking For">Looking for*</option>
                  <option value="India">India</option>
                  <option value="America">America</option>
                  <option value="Australia">Australia</option>
                  <option value="Bangladesh">Bangladesh</option>
                </select>
              </div>
              <div>
                <input
                  type="textarea"
                  className="form-control1"
                  placeholder="   Your Message"
                />
              </div>
              <div style={{ display: "flex" }}>
                <input type="checkbox" className="from-control mobBox" />
                <span className="textPolicyMob">
                  By clicking submit you agree to our{" "}
                  <span className="clicking">Privacy Policy</span>
                </span>
              </div>
              <button className="button">Contact Now</button>
            </form>
          </div>
          <div></div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default MobileLandingPage;
