import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Login from './Components/Login/Login';
import ChatUI from './Components/Chatapp/Chat';
import LandingPage from './Components/Landing_Page/Landing_Page';
// import SignInPage from './Components/Register/Register';

function App() {
  return (
    <div className="app">
      <Router>
        <Switch>
          {/* <Route path="/register" exact component={SignInPage} /> */}
          <Route path="/chat" exact component={ChatUI} />
          <Route path="/" exact component={LandingPage} />
           <Route path="/login_page" component={Login} /> 
        </Switch>
      </Router>
    </div>
  );
}

export default App;
