import React, { useState, useRef, useEffect, Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import MobileMenu from './MobileMenu';
import ReactMarkdown from 'react-markdown';
import './Chat.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DataSet } from 'vis-data/peer/umd/vis-data.min.js';
import { Network } from 'vis-network';
import { readString } from 'react-papaparse';
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
// import { Select, MenuItem, FormControl, InputLabel, OutlinedInput, Checkbox, ListItemText, Chip,Button } from '@mui/material';
// import csvFile from '../../circular_filenames_rbi_sebi_v6.csv'
import { DataGrid, GridToolbarExport } from "@mui/x-data-grid";
import Modal from './Modal';
import axios from 'axios';
import { Document, Page } from 'react-pdf';
import Plot from "react-plotly.js";
import PdfModalPage from './PdfModalPage';
import { Select, MenuItem, FormControl, InputLabel, OutlinedInput, Checkbox, ListItemText, Chip, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// import { DzGraphUiComponent, DzActionUiComponent, DzReferencesUiComponent } from './component';
import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { FaQuestionCircle, FaChartBar, FaExchangeAlt, FaListAlt, FaClipboardCheck, FaFileAlt ,FaInfoCircle} from 'react-icons/fa';


const PdfViewerComponent = ({ pdfUrl }) => {
  // Create new plugin instance
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  useEffect(() => {
    console.log('PDF URL changed:', pdfUrl);
  }, [pdfUrl]);
  return (
    <div>
      <Viewer
        fileUrl={pdfUrl}
        plugins={[
          // Register plugins
          defaultLayoutPluginInstance,
          // Add more plugins if needed
        ]}
      />
    </div>
  );
};


const ChatUI = () => {

  const urlParams = new URLSearchParams(window.location.href);
  const obj = Object.fromEntries(urlParams);
  var cir_number = obj['https://complieaze.ai/chat?circular_number']
  var cir_date = obj['date']
  if (typeof cir_date === 'undefined') {
    var circularNumber = `/circular ${cir_number}`
  } else {
    var circularNumber = `/circular ${cir_number} ${cir_date}`
  }

  if (typeof cir_number === 'undefined') {
    var circularNumber = '';
  }

  const [browseInputText, setBrowseInputText] = useState("");
  const [messages, setMessages] = useState([]);
  const [chatHistory, setChatHistory] = useState([]);
  const [inputText, setInputText] = useState(`${circularNumber}`);
  const [isLoading, setLoading] = useState(false);
  const [isBrowseLoading, setBrowseLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFormLoading, setFormLoading] = useState(false);
  const [activeChatIndex, setActiveChatIndex] = useState(null);
  const chatContainerRef = useRef(null);
  const [isLogoutVisible, setIsLogoutVisible] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [responseData, setResponseData] = useState(null);
  const [suggestionsVisible, setSuggestionsVisible] = useState(false);
  const [allSuggestions] = useState([
    // '/search',
    '/question How often should IRRBB policies be reviewed?',
    '/question What is the limit for offline payment transactions as of 2024?',
    '/question What is KYC?',
    '/question What is AML?',
    '/question What are the safeguards that banks should take for preventing misuse of lost/stolen cards?',
    '/question List the steps that banks with foreign branches should do to translate their financial statements',
    '/question What should banks do to obtain consent for sharing customer information?',
    '/question What should banks do to ensure that the consumer knows about the calculation of finance charges?',
    '/question What should banks do before reporting the default status of a credit card holder to CIBIL?',
    '/question What should banks do so that the identity of the person withdrawing cash from the ATM can be established?',
    '/question How should the request for closure of credit card be treated by the bank ?',
    '/circular DoR.REG/LIC.No.72/16.05.000/2023-24',
    '/action DoR.RET.REC.59/12.01.001/2023-24',
    '/changes DoR.RET.REC.59/12.01.001/2023-24',
    '/references DoR.RET.REC.59/12.01.001/2023-24',
    '/compliance',
    '/question',
    'How often should IRRBB policies be reviewed?',
    'What is the limit for offline payment transactions as of 2024?',
    'What is KYC?',
    'What is AML?',
    'What are the safeguards that banks should take for preventing misuse of lost/stolen cards?',
    'List the steps that banks with foreign branches should do to translate their financial statements',
    'What should banks do to obtain consent for sharing customer information?',
    'What should banks do to ensure that the consumer knows about the calculation of finance charges?',
    'What should banks do before reporting the default status of a credit card holder to CIBIL?',
    'What should banks do so that the identity of the person withdrawing cash from the ATM can be established?',
    'How should the request for closure of credit card be treated by the bank ?',
    '/help',
    '/info',
  ]);
  const [selectedTopic, setSelectedTopic] = useState('');
  const [selectedOption, setSelectedOption] = useState('Context');
  const [apiResponse, setApiResponse] = useState([]);
  const [viewMode, setViewMode] = useState("list"); // 'list' or 'graph'
  const [dimension, setDimension] = useState("2D");
  // const [showRegulatorForm, setShowRegulatorForm] = useState(false);
  const [pdfmodalOpen, setpdfModalOpen] = useState(false);
  const [selectedPdfUrl, setSelectedPdfUrl] = useState('');

  const openPdfInModal = (pdfUrl) => {
    setSelectedPdfUrl(pdfUrl);
    setpdfModalOpen(true);
  };

  const closePdfModal = () => {
    setSelectedPdfUrl('');
    setpdfModalOpen(false);
  };

  const handleFirstDropdownChange = event => {
    setSelectedTopic(event.target.value);
  };

  const handleSecondDropdownChange = event => {
    setSelectedOption(event.target.value);
  };


  const handleCircularNumberClick = (circularString) => {
    const circularNumberMatch = circularString.match(/RBI\/\d{4}-\d{4}\/\d+\n(.*)/);
    if (circularNumberMatch) {
      const extractedCircularNumber = circularNumberMatch[1];
      setInputText(`/circular ${extractedCircularNumber}`);
      setIsDarkMode(false);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [noDataMessage, setNoDataMessage] = useState('');
  
  const handleBrowseInputChange = event => {
    setBrowseInputText(event.target.value);
  };
  useEffect(() => {
    setTotalDataCount(apiResponse.length);
  }, [apiResponse]);

  const handleBrowseSubmit = async () => {
    const payload = {};

    if (selectedTopic) payload.topic = selectedTopic;
    if (startDate) payload.startDate = format(startDate, 'dd-MM-yyyy');
    if (endDate) payload.endDate = format(endDate, 'dd-MM-yyyy');
    if (selectedOption && browseInputText) {
      let inputText = browseInputText;
      if (selectedOption.toLowerCase().replace(/ /g, '_') === 'circular_number') {
        inputText = inputText.replace(/\\n/g, '\n');
      }

      payload[selectedOption.toLowerCase().replace(/ /g, '_')] = inputText;
    }

    setBrowseLoading(true);
    setApiResponse([]);
    setNoDataMessage('');

    try {
      const response = await axios.post('/browse_document', payload);

      if (Array.isArray(response.data) && response.data.length === 1 && typeof response.data[0] === 'string' && response.data[0].includes('An error occurred')) {
        setNoDataMessage('No documents found matching the search criteria.');
        setApiResponse([]);
      } else if (typeof response.data === 'string' && response.data.includes('No documents found matching the search criteria.')) {
        setNoDataMessage('No documents found matching the search criteria.');
        setApiResponse([]);
      } else if (response.data.length === 0) {
        setNoDataMessage('No documents found matching the search criteria.');
      } else {
        setApiResponse(response.data);
      }
    } catch (error) {
      console.error('Error submitting browse data:', error);
      setNoDataMessage('An error occurred while searching for documents.');
    } finally {
      setBrowseLoading(false);
    }
  };
  // const handleBrowseSubmit = async () => {
  //   const payload = {};
  
  //   if (selectedTopic) payload.topic = selectedTopic;
  //   if (startDate) payload.startDate = format(startDate, 'dd-MM-yyyy');
  //   if (endDate) payload.endDate = format(endDate, 'dd-MM-yyyy');
  //   if (selectedOption && browseInputText) {
  //     let inputText = browseInputText;
  //     if (selectedOption.toLowerCase().replace(/ /g, '_') === 'circular_number') {
  //       inputText = inputText.replace(/\\n/g, '\n');
  //     }
  
  //     payload[selectedOption.toLowerCase().replace(/ /g, '_')] = inputText;
  //   }
  
  //   setBrowseLoading(true);
  //   setApiResponse([]);
  //   setNoDataMessage('');
  
  //   try {
  //     const response = await axios.post('/browse_document', payload);
  
  //     if (Array.isArray(response.data) && response.data.length === 1 && typeof response.data[0] === 'string' && response.data[0].includes('An error occurred')) {
  //       setNoDataMessage('No documents found matching the search criteria.');
  //       setApiResponse([]);
  //     } else if (typeof response.data === 'string' && response.data.includes('No documents found matching the search criteria.')) {
  //       setNoDataMessage('No documents found matching the search criteria.');
  //       setApiResponse([]);
  //     } else if (response.data.length === 0) {
  //       setNoDataMessage('No documents found matching the search criteria.');
  //     } else {
  //       setApiResponse(response.data);
  //     }
  //   } catch (error) {
  //     console.error('Error submitting browse data:', error);
  //     setNoDataMessage('An error occurred while searching for documents.');
  //   } finally {
  //     setBrowseLoading(false);
  //   }
  // };
  const apiResponseColumns = [

    {
      field: "serialNumber",
      headerName: "Sr. No.",
      // width: 120,
      renderCell: (params) => params.row.serialNumber,
    },

    {
      field: "circularNumber",
      headerName: "Circular Number",
      flex: 1,
      renderCell: (params) => (
        <div title={params.value} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "date",
      headerName: "Date",
      width: 100,
      type: "date",
      valueGetter: (params) => {
        const dateStr = params.value;
        const [day, month, year] = dateStr.split("-");
        return new Date(year, month - 1, day);
      },
    },
    {
      field: "subject",
      headerName: "Subject",
      flex: 1,
      width: 90,
      renderCell: (params) => (
        <div title={params.value} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.value}
        </div>
      ),
    },
    { field: "document_type", headerName: "Document Type", flex: 1 },
    {
      field: "regulator",
      headerName: "Regulator",
      flex: 1,
      width: 90,
      renderCell: (params) => (
        <div title={params.value} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.value}
        </div>
      ),
    },
  ];
  const transformApiResponse = (apiResponse) => {
    return apiResponse.map((item, index) => ({
      id: item.id,
      serialNumber: index + 1,
      circularNumber: item.meta.circular_number || item.meta.subject || "NA",
      date: item.meta.date,
      subject: item.meta.subject,
      document_type: item.meta.regulator_document_type,
      regulator: item.regulator
    }));
  };

  // const startRowIndex = (currentPage - 1) * rowsPerPage;
  // const endRowIndex = startRowIndex + rowsPerPage;
  const currentData = transformApiResponse(apiResponse);
  console.log(currentData)
  const generateDummyEmbeddings = (numPoints) => {
    const embeddings2D = Array.from({ length: numPoints }, () => [
      Math.random(),
      Math.random(),
    ]);
    const embeddings3D = Array.from({ length: numPoints }, () => [
      Math.random(),
      Math.random(),
      Math.random(),
    ]);
    return { embeddings2D, embeddings3D };
  };

  const graphData = generateDummyEmbeddings(apiResponse.length);

  const toggleDimension = () => {
    setDimension(dimension === "2D" ? "3D" : "2D");
  };


  const graphLayout2D = {
    title: "Document Similarity Graph",
    autosize: true,
    margin: {
      l: 60,
      r: 60,
      b: 50,
      t: 70,
      pad: 10,
    },
    xaxis: { title: "Dimension 1" },
    yaxis: { title: "Dimension 2" },
  };

  const graphLayout3D = {
    title: "Document Similarity Graph",
    autosize: true,
    margin: {
      l: 60,
      r: 60,
      b: 50,
      t: 70,
      pad: 10,
    },
    scene: {
      xaxis: { title: "Dimension 1" },
      yaxis: { title: "Dimension 2" },
      zaxis: { title: "Dimension 3" },
    },
  };

  const suggestionIndexRef = useRef(-1);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
  const inputRef = useRef(null);
  const handleButtonClick = (commandText) => {
    setInputText(commandText);
    setChatPlaceholder('');
  };
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  // const placeholderText = (
  //   <div className="containerr">
  //     <div className='row1'>
  //       <button className="box" onClick={() => sendMessage('/question')}>
  //         <FaQuestionCircle className="icon" />
  //         <h4>/question</h4>
  //         <p>Get an answer to your query resolved along with appropriate references</p>
  //       </button>
  //       <button className="box" onClick={() => sendMessage('/references')}>
  //         <FaChartBar className="icon" />
  //         <h4>/references</h4>
  //         <p>Get an interactive graph showing the references made by a particular circular</p>
  //       </button>
  //       <button className="box" onClick={() => sendMessage('/changes')}>
  //         <FaExchangeAlt className="icon" />
  //         <h4>/changes</h4>
  //         <p>Get an interactive graph showing the changes made by a particular circular</p>
  //       </button>
  //     </div>
  //     <div className='row2'>
  //       <button className="box" onClick={() => sendMessage('/action')}>
  //         <FaListAlt className="icon" />
  //         <h4>/action</h4>
  //         <p>Get a table showing the action items present in a particular circular</p>
  //       </button>
  //       <button className="box" onClick={() => sendMessage('/compliance')}>
  //         <FaClipboardCheck className="icon" />
  //         <h4>/compliance</h4>
  //         <p>Get an interactive table showing all the RBI circulars</p>
  //       </button>
  //       <button className="box" onClick={() => sendMessage('/circular')}>
  //         <FaFileAlt className="icon" />
  //         <h4>/circular</h4>
  //         <p>Get important information about a circular along with an AI-generated summary</p>
  //       </button>
  //     </div>
  //   </div>
  // );

  // const sendMessage = (message) => {
  //   setChatPlaceholder('');
  //   setInputText(message);
  //   handleSendMessage(message);
  // };
  const placeholderText = (
    <div className="containerr">
       <div className='row1'>
        <button className="box" onClick={() => sendMessage('/question')}>
          <FaQuestionCircle className="icon" />
          <h3>question</h3>
          {/* <p>Get an answer to your query resolved along with appropriate references</p> */}
        </button>
        <button className="box" onClick={() => sendMessage('/references')}>
          <FaChartBar className="icon" />
          <h3>references</h3>
          {/* <p>Get an interactive graph showing the references made by a particular circular</p> */}
        </button>
        <button className="box" onClick={() => sendMessage('/changes')}>
          <FaExchangeAlt className="icon" />
          <h3>changes</h3>
          {/* <p>Get an interactive graph showing the changes made by a particular circular</p> */}
        </button>
      </div>
      <div className='row2'>
        <button className="box" onClick={() => sendMessage('/action')}>
          <FaListAlt className="icon" />
          <h3 >action</h3>
          {/* <p>Get a table showing the action items present in a particular circular</p> */}
        </button>
        <button className="box" onClick={() => sendMessage('/compliance')}>
          <FaClipboardCheck className="icon" />
          <h3>compliance</h3>
          {/* <p>Get an interactive table showing all the RBI circulars</p> */}
        </button>
        <button className="box" onClick={() => sendMessage('/circular')}>
          <FaFileAlt className="icon" />
          <h3>circular</h3>
          {/* <p>Get important information about a circular along with an AI-generated summary</p> */}
        </button>
      </div>
    </div>
  );
  const sendMessage = (userMessage) => {
    setChatPlaceholder('');
    let botMessage = '';
    if (userMessage === '/question') {
      botMessage = (
        <div>
        <ul>
          <li><strong>Description:</strong> Get an answer to your query resolved along with appropriate references</li>
          <li><strong>Syntax:</strong> /question &lt;user_query&gt;</li>
          <li><strong>Example:</strong> /question How often should IRRBB guidelines be reviewed?</li>
          <li><strong>Natural Language example:</strong> How often should IRBB guidelines be reviewed?</li>
        </ul>
        <a href="#!" onClick={handleBackToMenu}>Back to Menu</a>
        </div>
      );
    } 
    else if (userMessage === '/references') {
      botMessage = (
        <div>
        <ul>
          <li><strong>Description:</strong> Get an interactive graph showing the references made by a particular circular</li>
          <li><strong>Syntax:</strong> /references &lt;circular_number&gt;</li>
          <li><strong>Example:</strong> /references DoR.RET.REC.59/12.01.001/2023-24</li>
          <li><strong>Natural Language example:</strong> What are the circulars referred by circular DoR.RET.REC.59/12.01.001/2023-24</li>
        </ul>
        <a href="#!" onClick={handleBackToMenu}>Back to Menu</a>
        </div>
      );
    }
    else if (userMessage === '/changes') {
      botMessage = (
        <div>
        <ul>
          <li><strong>Description:</strong>  Get an interactive graph showing the changes made by a particular circular</li>
          <li><strong>Syntax:</strong> /changes &lt;circular_number&gt;</li>
          <li><strong>Example:</strong> /changes DoR.RET.REC.59/12.01.001/2023-24</li>
          <li><strong>Natural Language example:</strong> What are the changes made by circular DoR.RET.REC.59/12.01.001/2023-24</li>
        </ul>
        <a href="#!" onClick={handleBackToMenu}>Back to Menu</a>
        </div>
      );
    }
    else if (userMessage === '/action') {
      botMessage = (
        <div>
        <ul>
          <li><strong>Description:</strong> Get a table showing the action items present in particular circular</li>
          <li><strong>Syntax:</strong> /action &lt;circular_number&gt;</li>
          <li><strong>Example:</strong> /action DoR.RET.REC.59/12.01.001/2023-24</li>
          <li><strong>Natural Language example:</strong> What are action items mentioned in the circular DoR.RET.REC.59/12.01.001/2023-24</li>
        </ul>
        <a href="#!" onClick={handleBackToMenu}>Back to Menu</a>
        </div>
      );
    }
    else if (userMessage === '/compliance') {
      botMessage = (
        <div>
        <ul>
          <li><strong>Description:</strong> Get an interactive table showing all the RBI circulars</li>
          <li><strong>Syntax:</strong> /compliance</li>
          <li><strong>Example:</strong> /compliance</li>
          <li><strong>Natural Language example:</strong> 1. List all the RBI circulars from last month 
          2. List all the RBI circulars from first quarter of 2023</li>
        </ul>
        <a href="#!" onClick={handleBackToMenu}>Back to Menu</a>
        </div>
      );
    }
    else if (userMessage === '/circular') {
      botMessage = (
        <div>
        <ul>
          <li><strong>Description:</strong> Get the important information about a circular, an AI generated summary along with three buttons to access the references, changes and actions related to that circular.</li>
          <li><strong>Syntax:</strong> /circular &lt;circular_number&gt;</li>
          <li><strong>Example:</strong> /circular DoR.REG/LIC.No.72/16.05.000/2023-24</li>
          <li><strong>Natural Language example:</strong> Natural language support for this command is still under development</li>
        </ul>
        <a href="#!" onClick={handleBackToMenu}>Back to Menu</a>
        </div>
      );
    }
    else {
      botMessage = 'Here is the hard-coded response for ' + userMessage;
    }

    setMessages((prevMessages) => [
      ...prevMessages,
      { isUser: true, text: userMessage },
      { isUser: false, text: botMessage },
    ]);
  };
  const handleBackToMenu = () => {
    setMessages([]);
    setChatPlaceholder(placeholderText);
  };

  const [showRegulatorForm, setShowRegulatorForm] = useState(false);
  const [topicsByRegulator, setTopicsByRegulator] = useState({});

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    console.log("Toggling sidebar");
    setIsSidebarOpen(!isSidebarOpen);
  };
  const [errorMessage, setErrorMessage] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const handleChatContainerError = () => {
    setErrorMessage("Complieaze is down. Please contact the team.");
    setIsErrorModalOpen(true);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [selectedRegulator, setSelectedRegulator] = useState([]);
  const [topicsData, setTopicsData] = useState({});

  const [availableTopics, setAvailableTopics] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  useEffect(() => {
    if (selectedRegulator) {
      setAvailableTopics(topicsByRegulator[selectedRegulator]);
      setSelectedTopics([]);
    }
  }, [selectedRegulator]);

  useEffect(() => {
    async function fetchTopics() {
      try {
        const response = await axios.get('/get_topics');
        setTopicsData(response.data);
      } catch (error) {
        console.error('Error fetching topics data:', error);
      }
    }
    fetchTopics();
  }, []);

  const regulators = ['RBI', 'SEBI'];

  const handleRegulatorChange = (event) => {
    const { value } = event.target;
    setSelectedRegulator(typeof value === 'string' ? value.split(',') : value);
  };
  const fetchTopics = async () => {
    try {
      const response = await axios.post('/get_topic', { regulators: selectedRegulator });
      console.log('Topics Response:', response.data);
    } catch (error) {
      console.error('Error fetching topics data:', error);
    }
  };

  const fetchRegulator = async () => {
    try {
      const response = await axios.get('/get_regulator');
      const data = response.data;
      setRegulator(data.regulator);
      setMetadata(data.metadata);
      setQAContext(`${data.regulator} all documents`)

    } catch (error) {
      console.error('Error fetching regulator:', error);
    }
  };

  const handleSubmit = async () => {
    setFormLoading(true);
    try {
      await fetchTopics();
      await fetchRegulator();
    } catch (error) {
      console.error('Error during form submission:', error);
    } finally {
      setFormLoading(false);
      setShowRegulatorForm(false);
    }
  };

  const handleCloseForm = () => {
    setShowRegulatorForm(false);
  };

  const handleTopicChange = (event) => {
    const { value } = event.target;
    const updatedTopics = typeof value === 'string' ? value.split(',') : value;
    setSelectedTopics(updatedTopics);
    setTopicsByRegulator(prevState => ({
      ...prevState,
      [selectedRegulator]: updatedTopics,
    }));
  };

  const renderSelectedValue = (selected) => {
    if (selected.length > 2) {
      return (
        <div className="selected-topics">
          {selected.slice(0, 2).map((value) => (
            <Chip key={value} label={value} style={{ margin: 2 }} />
          ))}
          <span>...</span>
          {/* <div className="all-selected-topics">
            {selected.map((value) => (
              <Chip key={value} label={value} style={{ margin: 2 }} />
            ))}
          </div> */}
        </div>
      );
    }
    return selected.map((value) => (
      <Chip key={value} label={value} style={{ margin: 2 }} />
    ));
  };

  const fetchData = () => {
    fetch('https://complieaze.ai/chat')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Complieaze is not running currently. Please contact the team for the same.');
        }
        return response.json();
      })
      .then((data) => {
        // Process the data from the backend
      })
      .catch((error) => {
        // Check if the error status code is not 200
        if (error.response && error.response.status !== 200) {
          handleChatContainerError();
        }
      });
  };

  const closeModal = () => {
    setIsErrorModalOpen(false);
  };
  const [chatPlaceholder, setChatPlaceholder] = useState(placeholderText);
  const [isUserProfileDropdownOpen, setIsUserProfileDropdownOpen] = useState(false);

  const [isCommentFormOpen, setIsCommentFormOpen] = useState(false);

  const [isListening, setIsListening] = useState(false);
  const [transcript, setTranscript] = useState('');
  // const [isRecording, setIsRecording] = useState(false);
  const colors = ['orange', 'white', 'green'];
  const dotStyle = { marginRight: '5px' };
  const [dotSequence, setDotSequence] = useState(0);
  const [fetchAudioTrigger, setFetchAudioTrigger] = useState(false);

  useEffect(() => {
    let interval;
    if (isRecording) {
      interval = setInterval(() => {
        setDotSequence((dotSequence) => (dotSequence + 1) % 3);
      }, 500);
    } else {
      setDotSequence(0);
    }
    return () => clearInterval(interval);
  }, [isRecording]);

  // const handleRecordAndTranscribe = async () => {
  //   setIsRecording(true);
  //   try {
  //     const response = await axios.get('http://127.0.0.1:5000/asr');
  //     //setTranscript(response.data);
  //     setInputText(response.data);
  //   } catch (error) {
  //     console.error('Error:', error);
  //   } finally {
  //     setIsRecording(false);
  //   }
  // };
  // const handleKebabMenuClick = (event) => {
  //     event.stopPropagation();
  //     setKebabMenuOpen(!kebabMenuOpen);
  //   };
  // useEffect(() => {
  //     const fetchAudio = async () => {
  //       try {
  //         const response = await axios.get('http://127.0.0.1:5000/tts', {
  //         params: { text: assistantResponse },
  //         responseType: 'blob',
  //       });
  //         console.log('TTS Response:', response);

  //         const blob = new Blob([response.data], { type: 'audio/mp3' });

  //         if (blob.size > 0) {
  //           setAudioUrl(URL.createObjectURL(blob));
  //           // Trigger audio playback immediately
  //           const audio = new Audio(URL.createObjectURL(blob));
  //           audio.play().catch((error) => console.error('Error playing audio:', error));
  //         } else {
  //           console.error('Empty audio received from the TTS service.');
  //         }
  //       } catch (error) {
  //         console.error('Error fetching audio', error);
  //       }
  //     };


  //     if (fetchAudioTrigger) {
  //       fetchAudio();

  //       setFetchAudioTrigger(false);
  //     }
  //   }, [assistantResponse, fetchAudioTrigger]);


  //   const handleOptionClick = (option, event) => {
  //     event.stopPropagation();
  //     console.log(`Option "${option}" clicked!`);
  //     setKebabMenuOpen(false);
  //   };
  //   const renderRecordingText = () => {
  //     let dots = [];
  //     for (let i = 0; i < dotSequence + 1; i++) {
  //       dots.push(
  //         <span key={i} style={{ ...dotStyle, color: colors[i] }}>
  //           •
  //         </span>
  //       );
  //     }
  //     return dots;
  //   };
  const toggleUserProfileDropdown = () => {
    setIsUserProfileDropdownOpen(!isUserProfileDropdownOpen);
  };
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleInputChange = (event) => {
    const newText = event.target.value;
    setInputText(newText);
    const filteredSuggestions = allSuggestions.filter((suggestion) =>
      suggestion.toLowerCase().startsWith(newText.toLowerCase())
    );


    setSuggestions(filteredSuggestions);
    setSuggestionsVisible(newText.trim() !== '' && filteredSuggestions.length > 0);
    // setChatPlaceholder(newText.trim() === '' ? placeholderText : '');
    if (newText.trim() === '' && messages.length === 0) {
      setChatPlaceholder(placeholderText);
    } else {
      setChatPlaceholder('');
    }
  };

  class DzUiComponent {

    constructor() {
      if (this.constructor === DzUiComponent) {
        throw new Error("Abstract classes can't be instantiated.");
      }
    }

    transform(response) {
      throw new Error("Not implemented");
    }

  }

  class DzChangesUiComponent extends DzUiComponent {
    constructor() {
      super();
    }
    transform(response) {
      const graphData_unparsed = response['content'];
      console.log(graphData_unparsed)
      // console.log("asst response", graphData_unparsed.asstresponse)
      let botResponse = "";
      if (graphData_unparsed.asstresponse === "Error: No context provided.") {
        botResponse = setBotResponse(graphData_unparsed.asstresponse);
      } else if (graphData_unparsed.asstresponse.includes("No changes found")) {
        botResponse = setBotResponse(`No changes for this document`);
      } else {
        try {
          const graphData = JSON.parse(graphData_unparsed.asstresponse);
          const title = `Changes made by Circular No. ${graphData.circular[0].name}`
          const graphComponent = <Graph title={title} jsonData={graphData} />;
          botResponse = setBotResponse(graphComponent, true);
        } catch (error) {
          botResponse = setBotResponse(`No changes for this document`);
          console.log(error);
        }
      }
      setMessages((prevMessages) => [...prevMessages, botResponse]);
      setLoading(false);
    }
  }

  class DzActionUiComponent extends DzUiComponent {
    constructor() {
      super();
      // this.handleSubmit = this.handleSubmit.bind(this);
      // this.toggleFormVisibility = this.toggleFormVisibility.bind(this);
      this.isFormVisible = {};
    }

    // let isFormVisible = false;
    toggleFormVisibility = () => {
      this.isFormVisible = !this.isFormVisible;
      const formContainer = document.querySelector('.form-container');
      if (formContainer) {
        formContainer.style.display = this.isFormVisible ? 'block' : 'none';
      }
    };


    handleSubmit = async (event) => {
      event.preventDefault();

      // Extract action status and comment from form fields
      const actionStatus = event.target.elements.actionStatus.value;
      const comment = event.target.elements.comment.value;

      // Check if either action status or comment is empty
      if (actionStatus === 'select-action' || comment.trim() === '') {
        window.alert("Please select action status and provide a comment.");
        return; // Stop further execution
      }

      // Make API call
      try {
        const response = await fetch('/add/circular_action_status', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            circular_number: this.actionCirculars.cir_num, // Assuming cir_num is accessible here
            action_status: actionStatus,
            comment: comment,
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to insert data');
        }

        event.target.reset();

        // Handle success response
        const responseData = await response.json();
        console.log(responseData);

        window.alert("Your submission was successful!");

        this.toggleFormVisibility();

        // Additional logic after successful submission (e.g., show success message)
      } catch (error) {
        console.error('Error:', error);
      }
    };

    transform(response) {
      this.actionCirculars = response['content'];

      const cir_num = this.actionCirculars['cir_num'];
      const summary = this.actionCirculars["summary"];
      const action_required = this.actionCirculars["action"];
      const previous_deadline = this.actionCirculars["prevdeadline"];
      const new_deadline = this.actionCirculars["newdeadline"];
      const actionTable = (
        <table className="compliance-table" style={{ borderCollapse: 'collapse', width: '95%' }}>
          <thead>
            <tr style={{ backgroundColor: '#f2f2f2' }}>
              <th style={{ padding: '8px', textAlign: 'center', borderBottom: '1px solid #ddd' }}>Action Required</th>
              <th style={{ padding: '8px', textAlign: 'center', borderBottom: '1px solid #ddd' }}>Previous Deadline</th>
              <th style={{ padding: '8px', textAlign: 'center', borderBottom: '1px solid #ddd' }}>New Deadline</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{action_required}</td>
              <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{previous_deadline}</td>
              <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{new_deadline}</td>
            </tr>
          </tbody>
        </table>
      );

      // let isFormVisible = false; // Initialize form visibility state

      // const toggleFormVisibility = () => {
      //   isFormVisible = !isFormVisible;

      //   // Locate form and toggle display style
      //   const formContainer = document.querySelector('.form-container');
      //   if (formContainer) {
      //     formContainer.style.display = isFormVisible ? 'block' : 'none';
      //   }
      // };

      let botResponse = {
        text: (
          <div>
            <h3 style={{ color: '#333' }}>{cir_num}</h3>
            <p style={{ marginBottom: '20px' }}> <strong>Summary: </strong> {summary}</p>
            {actionTable}
            <img
              src={process.env.PUBLIC_URL + '/chatbot.png'}
              alt="Comment"
              className='comment-btn'
              onClick={this.toggleFormVisibility}
            // style={{float: 'right'}}
            />
            <form onSubmit={(event) => this.handleSubmit(event)} className="form-container" style={{ display: 'none' }}>
              <div className="form-action">
                <label htmlFor="actionStatus">Action Status:</label>
                <select name="actionStatus" id="actionStatus" className="action-status-dropdown">
                  <option value="select-action">Select Action Status</option>
                  <option value="completed">Completed</option>
                  <option value="incomplete">Incomplete</option>
                </select>
              </div>
              <div className="form-comment">
                <label htmlFor="comment">Comment:</label>
                <textarea id="comment" name="comment" className="comment-input" rows="4" cols="50" placeholder="Add your comment here..."></textarea>
              </div>
              <button type="submit" className="form-submit-button">Submit</button>
            </form>

          </div>
        ),
        isTable: true,
      };
      setMessages((prevMessages) => [...prevMessages, botResponse]);
      setLoading(false);
    }
  }

  class DzTextUiComponent extends DzUiComponent {
    constructor() {
      super();
    }

    //  transform(res) {
    //     try {
    //       const content = res['content'];
    //       const asstResponse = content['asstresponse'];

    //       console.log(`asstResponse ${asstResponse}`)
    //       let botResponse = {
    //         text: (
    //           <span>
    //              <div dangerouslySetInnerHTML={{ __html: asstResponse }} />
    //           </span>
    //         ),
    //       };

    //       setMessages((prevMessages) => [...prevMessages, botResponse]);
    //       setLoading(false);
    //     } catch (error) {
    //       console.error("Error in transforming search UI component:", error);
    //       return setBotResponse("Error: Failed to process response data.");
    //     }
    //   }
    async transform(res) {
      try {
        const content = res['content'];
        const asstResponse = content['asstresponse'];

        console.log(`asstResponse ${asstResponse}`);

        let botResponse = {};
        if (asstResponse.includes('<script')) {
          const scriptUrlRegex = /<script.*?src=["'](.*?)["']/;
          const match = asstResponse.match(scriptUrlRegex);
          if (match) {
            const scriptUrl = match[1];
            const proxyUrl = 'https://cors.bridged.cc/';
            const response = await fetch(proxyUrl + scriptUrl);
            const scriptContent = await response.text();

            botResponse = {
              text: (
                <span>
                  <ReactMarkdown>{scriptContent}</ReactMarkdown>
                </span>
              ),
            };
          } else {
            console.error('Script URL not found in the response');
            botResponse = {
              text: (
                <span>
                  <div dangerouslySetInnerHTML={{ __html: asstResponse }} />
                </span>
              ),
            };
          }
        } else {
          console.error("Gist file not found");
          botResponse = {
            text: (
              <span>
                <div dangerouslySetInnerHTML={{ __html: asstResponse }} />
              </span>
            ),
          };
        }
        setMessages((prevMessages) => [...prevMessages, botResponse]);
        setLoading(false);
      } catch (error) {
        console.error("Error in transforming search UI component:", error);
        return setBotResponse("Error: Failed to process response data.");
      }
    }


  }
  class DzReferencesUiComponent extends DzUiComponent {
    constructor() {
      super();
    }
    transform(response) {
      try {
        const graphData_unparsed = response['content'];
        console.log(graphData_unparsed)
        let botResponse;
        if (graphData_unparsed.asstresponse === "Error: No context provided.") {
          botResponse = setBotResponse(graphData_unparsed.asstresponse);
        } else if (graphData_unparsed.asstresponse.includes("No relationships for this document")) {
          botResponse = setBotResponse(`No references for this document`);
        } else {
          const graphData = JSON.parse(graphData_unparsed.asstresponse);
          console.log("graphdata", graphData)
          const title = `References for Circular No. ${graphData.circular[0].name}`
          const graphComponent = <Graph title={title} jsonData={graphData} />;
          console.log("graphComponent", graphComponent)
          botResponse = setBotResponse(graphComponent, true);
          console.log("botresponse", botResponse)
        }
        setMessages((prevMessages) => [...prevMessages, botResponse]);
        setLoading(false);
        return botResponse;
      } catch (error) {
        console.log("Error in component")
        console.log(error)
        return setBotResponse(`No references for this document`);
      }
    }
  }

  class DzSearchUiComponent extends DzUiComponent {
    constructor() {
      super();
    }

    transform(res) {
      try {
        const assistantMessages = res;
        console.log(assistantMessages)
        const content = assistantMessages['content'];
        console.log(content['asstresponse'])
        console.log(content['pdf_link'])

        // if (!content || !content['asstresponse'] || !content['pdf_link'] || !content['page_num'] || !content['distance']) {
        //   return setBotResponse("Error: Incomplete response data.");
        // }

        const asstResponse = content['asstresponse'];
        const pdfLinks = content['pdf_link'];
        const pageNumbers = content['page_num'];
        const distances = content['distance'];
        const pdfNames = content['pdf_name'] || []; // Using empty array as default if pdf_name is not provided
        const docIds = content['doc_id']
        const QA_context = content['qa_context']
        setQAContext(`${QA_context}`)
        console.log(`doc_ids: ${docIds}`)

        let botResponse = {
          text: (
            <span>
              {pdfmodalOpen && <PdfModalPage pdfUrl={selectedPdfUrl} onClose={closePdfModal} />}
              <div dangerouslySetInnerHTML={{ __html: asstResponse }}
                style={{ whiteSpace: 'pre-wrap' }} />
              <br />
              {pdfLinks && pdfLinks.length > 0 && (
                <div>
                  <table className="pdf-table">
                    <thead>
                      <tr>
                        <th>Sr. Number</th>
                        <th>PDF Name</th>
                        <th>Page Number</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pdfLinks.map((pdfLink, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            {/* <a href="#" onClick={(e) => handlepdfClick(pdfLink)}> */}
                              <a href={pdfLink} onClick={(e) => openPdfInMinimizedWindow(e, pdfLink)}>
                              {pdfNames[index] || `PDF ${index + 1}`}
                            </a>
                          </td>
                          <td>{pageNumbers[index]}</td>
                          <td>{distances[index]}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              {/* <FontAwesomeIcon
                  icon={isPlaying ? faCircleNotch : faVolumeUp}
                  spin={isPlaying}
                  onClick={() => {
                    const extractTextContent = (element) => {
                      if (typeof element === 'string') {
                        return element; 
                      } else if (Array.isArray(element)) {
                        return element.map(extractTextContent).join(' '); 
                      } else if (element.props && element.props.children) {
                        return extractTextContent(element.props.children); 
                      } else if (element.props && element.props.dangerouslySetInnerHTML) {
                        return element.props.dangerouslySetInnerHTML.__html; 
                      } else {
                        return ''; 
                      }
                    };

                    const textContent = extractTextContent(botResponse.text);
                    handleTextToSpeech(textContent);
                  }}
                  style={{ marginLeft: '5px', cursor: 'pointer', opacity: isPlaying ? 0.5 : 1 }}
                  disabled={isPlaying}
                /> */}


            </span>
          ),
        };
        {
          pdfUrl && (
            <div className="pdf-viewer-container">
              <button onClick={closePdfViewer}>Close PDF Viewer</button>
              <div className="pdf-viewer">
                <Document file={pdfUrl}>
                  <Page pageNumber={1} />
                </Document>
              </div>
            </div>
          )
        }
        setMessages((prevMessages) => [...prevMessages, botResponse]);
        setLoading(false);
      } catch (error) {
        console.error("Error in transforming search UI component:", error);
        return setBotResponse("Error: Failed to process response data.");
      }
    }
  }

  // class DzSearchUiComponent extends DzUiComponent {
  //   constructor(props) {
  //     super(props);
  //     this.state = {
  //       isPdfViewerOpen: false,
  //       pdfUrl: null,
  //     };
  //   }

  //   openPdfViewer = (e, pdfUrl) => {
  //     e.preventDefault();
  //     const { updatePdfViewerState } = this.props;
  //     if (updatePdfViewerState) {
  //       updatePdfViewerState(true, pdfUrl);
  //     }
  //   };

  //   closePdfViewer = () => {
  //     const { updatePdfViewerState } = this.props;
  //     if (updatePdfViewerState) {
  //       updatePdfViewerState(false, null);
  //     }
  //   };

  //   transform(res) {
  //     try {
  //       const assistantMessages = res;
  //       const content = assistantMessages['content'];

  //       const asstResponse = content['asstresponse'];
  //       const pdfLinks = content['pdf_link'];
  //       const pageNumbers = content['page_num'];
  //       const distances = content['distance'];
  //       const pdfNames = content['pdf_name'] || [];

  //       let botResponse = {
  //         text: (
  //           <span>
  //             <div dangerouslySetInnerHTML={{ __html: asstResponse }} />
  //             <br />
  //             {pdfLinks && pdfLinks.length > 0 && (
  //               <div>
  //                 <table className="pdf-table">
  //                   <thead>
  //                     <tr>
  //                       <th>Sr. Number</th>
  //                       <th>PDF Name</th>
  //                       <th>Page Number</th>
  //                       <th>Date</th>
  //                     </tr>
  //                   </thead>
  //                   <tbody>
  //                     {pdfLinks.map((pdfLink, index) => (
  //                       <tr key={index}>
  //                         <td>{index + 1}</td>
  //                         <td>
  //                           <a href={pdfLink} onClick={(e) => this.openPdfViewer(e, pdfLink)}>
  //                             {pdfNames[index] || `PDF ${index + 1}`}
  //                           </a>
  //                         </td>
  //                         <td>{pageNumbers[index]}</td>
  //                         <td>{distances[index]}</td>
  //                       </tr>
  //                     ))}
  //                   </tbody>
  //                 </table>
  //               </div>
  //             )}
  //           </span>
  //         ),
  //       };

  //       setMessages((prevMessages) => [...prevMessages, botResponse]);
  //       setLoading(false);
  //     } catch (error) {
  //       console.error("Error in transforming search UI component:", error);
  //       return setBotResponse("Error: Failed to process response data.");
  //     }
  //   }
  // }


  // ParentComponent.js

  class ParentComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isPdfViewerOpen: false,
        pdfUrl: null,
      };
    }

    updatePdfViewerState = (isPdfViewerOpen, pdfUrl) => {
      this.setState({ isPdfViewerOpen, pdfUrl });
    };

    render() {
      const { isPdfViewerOpen, pdfUrl } = this.state;

      return (
        <div>
          {/* Your other components */}
          <DzSearchUiComponent updatePdfViewerState={this.updatePdfViewerState} />
          {/* Your other components */}
          {isPdfViewerOpen && pdfUrl && (
            <div className="pdf-viewer-container">
              <button onClick={this.closePdfViewer}>Close PDF Viewer</button>
              <div className="pdf-viewer">
                <Document file={pdfUrl}>
                  <Page pageNumber={1} />
                </Document>
              </div>
            </div>
          )}
        </div>
      );
    }
  }
  const handlepdfClick = (pdfUrl) => {
    openPdfInModal(pdfUrl);
  };
  class DzTableUiComponent extends DzUiComponent {
    constructor() {
      super();
    }
    transform(response) {
      const responseData = response;
      const content = responseData['content'];
      const circular_list = content['circular_list'] || [];
      const date = content['date_list'] || [];
      const title = content['title_list'] || [];
      const deadline = content['deadline_list'] || [];
      // const status = content['status_list'];
      const recipients = content['recipient_list'] || [];
      const sender = content['sender_list'] || [];
      const pdf_url = content['pdf_url']
      const regulators = content["regulator_list"]

      const circularTableData = circular_list.map((circular, index) => ({
        id: index,
        Circular: circular?.trim() || 'Not Applicable',
        Date: date[index]?.trim() || 'Not Applicable',
        Title: title[index]?.trim() || 'Not Applicable',
        // Deadline: deadline[index]?.trim() || 'Not Applicable',
        // Status: status[index],
        // Recipients: recipients[index]?.trim() || 'Not Applicable',
        // Sender: sender[index]?.trim() || 'Not Applicable',
        Pdf_url: pdf_url[index],
        Regulators:regulators[index]
      }));

      const circularTable = (
        <table className="compliance-table">
          <thead>
            <tr>
              <th>Circular</th>
              <th>Date</th>
              <th>Title</th>
              <th>Regulator type</th>
              {/* <th>Deadline</th> */}
              {/* <th>Status</th> */}
              {/* <th>Recipients</th> */}
              {/* <th>Sender</th> */}
            </tr>
          </thead>
          <tbody>
            {circularTableData.map((row, index) => (
              <tr key={index}>
                <td>{row.Circular}</td>
                <td>{row.Date}</td>
                <td>{row.Title}</td>
                <td>{row.Regulators}</td>
                {/* <td>{row.Deadline}</td> */}
                {/* <td>{row.Status}</td> */}
                {/* <td>{row.Recipients}</td> */}
                {/* <td>{row.Sender}</td> */}
              </tr>
            ))}
          </tbody>
        </table>
      );
      const CustomToolbar = () => {
        return (
          <div style={{ padding: "10px" }}>
            <GridToolbarExport />
          </div>
        );
      };

      const CustomCell = (params) => (
        <div className="compliance-dropdown">
          <a href="#" onClick={(e) => {
            handleCircularClick2(params.row.Pdf_url);
            console.log('Circular:', params.value);
            console.log('Date:', params.row.Date);
            handleCircularClick(e, params.value, params.row.Date);
          }}>
            {params.value}
          </a>
          <div className="dropdown-content">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleCircularClick(e, params.value, params.row.Date);
                // const selectedOption = e.target.textContent;
                // setInputText("/references");
                handleSendMessage("/references");
                // var element = document.getElementById('autoclick');
                // console.log(element.className, "Class Name")
                // console.log(element.id, "ID")
                // simulateMouseClick(element);
                // document.getElementById("autoclick").click()
                // document.getElementById("autoclick").addEventListener("click", simulateClick);
              }}
            >
              What are the documents referred?
            </a>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleCircularClick(e, params.value, params.row.Date);
                // const selectedOption = e.target.textContent;
                handleSendMessage("/changes");
              }}
            >
              What are the documents changed?
            </a>
            {/* <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleCircularClick(e, params.value);
                          // const selectedOption = e.target.textContent;
                          setInputText("/relevance");
                        }}
                      >
                        Who is it addressed to?
                      </a> */}
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleCircularClick(e, params.value, params.row.Date);
                // const selectedOption = e.target.textContent;
                handleSendMessage("/action");
              }}
            >
              What are the main action item(s)?
            </a>
          </div>
        </div>
      );
      let botResponse = {
        text: (
          <div>
            <span>
              Below is the list of circulars that you requested.<br />
              You can navigate through all the circulars from this table. Use any of the following commands to find out more about the circulars: <br />
              <ul>
                <li>/action -- What are the action items mentioned in the circular?</li>
                <li>/references -- What all Circulars/Acts/Regulations are referred in this circular? </li>
                <li>/changes -- What are the changes mentioned in this circular? </li>
              </ul>
            </span>
            <br />
            <span className="headClass">Circulars:</span>
            <div style={{ height: 700, width: "100%" }}>
              <DataGrid
                rows={circularTableData}
                columns={[
                  {
                    field: "id",
                    headerName: "Sr.Number",
                    cellClassName: "circularStyle",
                    width: 80,
                    hide: true,
                    height: 100,
                  },
                  {
                    field: "Circular",
                    headerName: "Circular",
                    cellClassName: "circularStyle",
                    renderCell: CustomCell,
                    height: 200,
                    flex: 1,
                  },
                  {
                    field: "Date",
                    headerName: "Date",
                    cellClassName: "circularStyle",
                    width: 100,
                    type: "date",
                    height: 100,
                    valueGetter: (params) => {
                      const [day, month, year] = params.value.split("-");
                      return new Date(year, month - 1, day);
                    },
                  },
                  {
                    field: "Title",
                    headerName: "Title",
                    cellClassName: "Title",
                    flex: 4,
                    height: 100,
                  },
                  {
                    field: "Regulators",
                    headerName: "Regulators",
                    cellClassName: "Regulator",
                    flex: 4,
                    height: 100,
                  },
                  // {
                  //   field: "Deadline",
                  //   headerName: "Deadline",
                  //   cellClassName: "circularStyle",
                  //   flex: 1,
                  //   height: 100,
                  // },
                  // {
                  //   field: "Status",
                  //   headerName: "Status",
                  //   height: 100,
                  //   width: 80,
                  // },
                  // {
                  //   field: "Recipients",
                  //   headerName: "Recipients",
                  //   cellClassName: "Recipients",
                  //   flex: 1,
                  //   height: 100
                  // },
                  // {
                  //   field: "Sender",
                  //   headerName: "Sender",
                  //   cellClassName: "Sender",
                  //   flex: 1,
                  //   height: 100,
                  // },
                ]}
                pageSize={20}
                pagination={true}
                rowsPerPageOptions={[20, 50, 100]}
                getRowClassName={() => 'customRowStyle'}
                onRowClick={(params) => { }}
                components={{
                  Toolbar: CustomToolbar,
                }}
              />
            </div>
          </div>
        ),
        isTable: true,
      };
      setMessages((prevMessages) => [...prevMessages, botResponse]);
      setLoading(false);
    }
  }
  class DzCircularUiComponent extends DzUiComponent {
    constructor() {
      super();
    }
    transform(response) {
      try {
        const content = response['content'];
        const circularMetadata = content['circular_metadata'];
        console.log("circularmetadata", circularMetadata)
        const referencesContent = content['references'];
        const changesContent = content['changes'];
        const actionContent = content['action'];
        console.log("referencesContent", referencesContent)
        console.log("changesContent", changesContent)
        setQAContext(`${circularMetadata['name']} (${circularMetadata['regulator']})`);
        const circularTable = (
          <div className="circular-metadata">
            <span className="headClasss">Circular Metadata:</span>
            <div>
              <div className="metadata-part">Name:
                <a href={circularMetadata['path']} onClick={(e) => openPdfInMinimizedWindow(e, circularMetadata['path'])}>
                  {circularMetadata['name']}
                </a>
              </div><br />
              <div className="metadata-part">Date: {circularMetadata['date']}</div><br />
              <div className="metadata-part">Subject: {circularMetadata['subject']}</div><br />
              <div className="metadata-part">Summary: {circularMetadata['summary']}</div><br />
            </div>
          </div>
        );

        let botResponse = {
          text: (
            <div className="bot-response">
              {circularTable}
              <div className="button-containerr">
                <button className="action-button" onClick={() => this.handleReferencesClick(referencesContent)}>References</button>
                <button className="action-button" onClick={() => this.handleChangesClick(changesContent)}>Changes</button>
                <button className="action-button" onClick={() => this.handleActionClick(actionContent)}>Action</button>
              </div>
            </div>
          ),
        };

        setMessages((prevMessages) => [...prevMessages, botResponse]);
        setLoading(false);
      } catch (error) {
        console.error("Error in transforming circular UI component:", error);
        return setBotResponse("Error: Failed to process response data.");
      }

    }

    handleReferencesClick(referencesContent) {
      // console.log("getting called")
      // console.log("References content:", referencesContent); 
      const referencesUiComponent = new DzReferencesUiComponent();
      referencesUiComponent.transform(referencesContent);
    }

    handleChangesClick(changesContent) {
      const changesUiComponent = new DzChangesUiComponent();
      changesUiComponent.transform(changesContent);
    }

    handleActionClick(actionContent) {
      const actionUiComponent = new DzAuditorUiComponent();
      actionUiComponent.transform(actionContent);
    }
  }
  class DzAuditorUiComponent extends DzUiComponent {
    constructor() {
      super();
      this.handleSubmit = this.handleSubmit.bind(this);
      this.toggleFormVisibility = this.toggleFormVisibility.bind(this);
      this.handleDownload = this.handleDownload.bind(this);
      this.isFormVisible = false;
    }

    toggleFormVisibility = () => {
      this.isFormVisible = !this.isFormVisible;
      const formContainer = document.querySelector('.form-container');
      if (formContainer) {
        formContainer.style.display = this.isFormVisible ? 'block' : 'none';
      }
    };

    handleSubmit = async (event) => {
      event.preventDefault();

      // Extract action status and comment from form fields
      const actionStatus = event.target.elements.actionStatus.value;
      const comment = event.target.elements.comment.value;

      // Check if either action status or comment is empty
      if (actionStatus === 'select-action' || comment.trim() === '') {
        window.alert("Please select action status and provide a comment.");
        return; // Stop further execution
      }

      // Make API call
      try {
        const response = await fetch('/add/circular_action_status', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            circular_number: this.actionCirculars.cir_num, // Assuming cir_num is accessible here
            action_status: actionStatus,
            comment: comment,
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to insert data');
        }

        event.target.reset();

        // Handle success response
        const responseData = await response.json();
        console.log(responseData);

        window.alert("Your submission was successful!");

        this.toggleFormVisibility();

        // Additional logic after successful submission (e.g., show success message)
      } catch (error) {
        console.error('Error:', error);
      }
    };

    handleDownload = async (jsonResponse, title) => {
      try {
        const response = await fetch('/download_excel', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ json_output: jsonResponse }),
        });

        if (!response.ok) {
          throw new Error('Failed to download');
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${title}.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);

        window.alert("Download successful!");
      } catch (error) {
        console.error('Error:', error);
        window.alert("Download failed!");
      }
    };


    async transform(res) {
      try {
        const content = res['content'];
        const asstResponse = content['asstresponse'];
        const title = content['title'];
        const pdf_path = content['pdf_path'];
        const json_response = content['json_output'];

        console.log(`asstResponse ${asstResponse}`);

        let botResponse = {};
        if (asstResponse.includes('<script')) {
          const scriptUrlRegex = /<script.*?src=["'](.*?)["']/;
          const match = asstResponse.match(scriptUrlRegex);
          if (match) {
            const scriptUrl = match[1];
            const proxyUrl = 'https://cors.bridged.cc/';
            const response = await fetch(proxyUrl + scriptUrl);
            const scriptContent = await response.text();
            botResponse = {
              text: (
                <div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <a
                      href={pdf_path}
                      onClick={(e) => openPdfInMinimizedWindow(e, pdf_path)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h2 style={{ color: '#333' }}>{title}</h2>
                    </a>
                    {json_response !== null && (
                      //   <button style={{
                      //     marginLeft: '10px',
                      //     padding: '10px',
                      //     fontSize: '20px',
                      //     color: 'blue',
                      // }}
                      //       onClick={() => this.handleDownload(json_response,title)}
                      //        title="Download file"
                      //   >
                      //       Download
                      //   </button>
                      <button
                        style={{
                          marginLeft: '10px',
                          padding: '10px',
                          color: 'blue',
                          fontSize: '20px',
                          background: 'none',
                          border: 'none',
                          cursor: 'pointer',
                        }}
                        onClick={() => this.handleDownload(json_response, title)}
                        title="Download file"
                      >
                        <i className="fas fa-download"></i>
                      </button>
                    )}
                  </div>
                  <span>
                    <ReactMarkdown>{scriptContent}</ReactMarkdown>
                  </span>
                </div>
              ),
            };
          } else {
            botResponse = {
              text: (
                <div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <a
                      href={pdf_path}
                      onClick={(e) => openPdfInMinimizedWindow(e, pdf_path)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h2 style={{ color: '#333' }}>{title}</h2>
                    </a>
                    {json_response !== null && (
                      // <button
                      //     onClick={() => this.handleDownload(json_response,title)}
                      // >
                      //     Download
                      // </button>
                      <button
                        style={{
                          marginLeft: '10px',
                          padding: '10px',
                          color: 'blue',
                          fontSize: '20px',
                          background: 'none',
                          border: 'none',
                          cursor: 'pointer',
                        }}
                        onClick={() => this.handleDownload(json_response, title)}
                        title="Download file"
                      >
                        <i className="fas fa-download"></i>
                      </button>
                    )}
                  </div>
                  <span>
                    <div dangerouslySetInnerHTML={{ __html: asstResponse }} />
                  </span>
                </div>
              ),
            };
          }
        } else {
          botResponse = {
            text: (
              <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <a
                    href={pdf_path}
                    onClick={(e) => openPdfInMinimizedWindow(e, pdf_path)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <h2 style={{ color: '#333' }}>{title}</h2>
                  </a>
                  {json_response !== null && (
                    // <button
                    //     onClick={() => this.handleDownload(json_response,title)}
                    // >
                    //     Download
                    // </button>
                    <button
                      style={{
                        marginLeft: '10px',
                        padding: '10px',
                        fontSize: '20px',
                        color: 'blue',
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                      }}
                      onClick={() => this.handleDownload(json_response, title)}
                      title="Download file"
                    >
                      <i className="fas fa-download"></i>
                    </button>
                  )}
                </div>
                <span>
                  <div dangerouslySetInnerHTML={{ __html: asstResponse }} />
                </span>
                <img
                  src={process.env.PUBLIC_URL + '/chatbot.png'}
                  alt="Comment"
                  className='comment-btn'
                  onClick={this.toggleFormVisibility}
                />
                <form onSubmit={(event) => this.handleSubmit(event)} className="form-container" style={{ display: 'none' }}>
                  <div className="form-action">
                    <label htmlFor="actionStatus">Action Status:</label>
                    <select name="actionStatus" id="actionStatus" className="action-status-dropdown">
                      <option value="select-action">Select Action Status</option>
                      <option value="completed">Completed</option>
                      <option value="incomplete">Incomplete</option>
                    </select>
                  </div>
                  <div className="form-comment">
                    <label htmlFor="comment">Comment:</label>
                    <textarea id="comment" name="comment" className="comment-input" rows="4" cols="50" placeholder="Add your comment here..."></textarea>
                  </div>
                  <button type="submit" className="form-submit-button">Submit</button>
                </form>
              </div>
            ),
          };
        }
        setMessages((prevMessages) => [...prevMessages, botResponse]);
        setLoading(false);
      } catch (error) {
        console.error("Error in transforming search UI component:", error);
        return setBotResponse("Error: Failed to process response data.");
      }
    }
  }

  // class DzAuditorUiComponent extends DzUiComponent {
  //       constructor() {
  //         super();
  //         this.handleSubmit = this.handleSubmit.bind(this);
  //         this.toggleFormVisibility = this.toggleFormVisibility.bind(this);
  //         this.isFormVisible = false;
  //       }

  //     toggleFormVisibility = () => {
  //       this.isFormVisible = !this.isFormVisible;
  //       const formContainer = document.querySelector('.form-container');
  //       if (formContainer) {
  //         formContainer.style.display = this.isFormVisible ? 'block' : 'none';
  //       }
  //     };


  //     handleSubmit = async (event) => {
  //       event.preventDefault();

  //       // Extract action status and comment from form fields
  //       const actionStatus = event.target.elements.actionStatus.value;
  //       const comment = event.target.elements.comment.value;

  //       // Check if either action status or comment is empty
  //       if (actionStatus === 'select-action' || comment.trim() === '') {
  //         window.alert("Please select action status and provide a comment.");
  //         return; // Stop further execution
  //       }

  //       // Make API call
  //       try {
  //         const response = await fetch('/add/circular_action_status', {
  //           method: 'POST',
  //           headers: {
  //             'Content-Type': 'application/json',
  //           },
  //           body: JSON.stringify({
  //             circular_number: this.actionCirculars.cir_num, // Assuming cir_num is accessible here
  //             action_status: actionStatus,
  //             comment: comment,
  //           }),
  //         });

  //         if (!response.ok) {
  //           throw new Error('Failed to insert data');
  //         }

  //         event.target.reset();


  //         // Handle success response
  //         const responseData = await response.json();
  //         console.log(responseData); 

  //         window.alert("Your submission was successful!");

  //         this.toggleFormVisibility();


  //         // Additional logic after successful submission (e.g., show success message)
  //       } catch (error) {
  //         console.error('Error:', error);
  //       }
  //     };
  //     async transform(res) {
  //       try {
  //           const content = res['content'];
  //           const asstResponse = content['asstresponse'];
  //           const title = content['title'];
  //           const pdf_path = content['pdf_path'];

  //           console.log(`asstResponse ${asstResponse}`);

  //           let botResponse = {};
  //           console.log("test1")
  //           if (asstResponse.includes('<script')) {
  //             const scriptUrlRegex = /<script.*?src=["'](.*?)["']/;
  //             const match = asstResponse.match(scriptUrlRegex);
  //             if (match) {
  //                 const scriptUrl = match[1];
  //             const proxyUrl = 'https://cors.bridged.cc/'; 
  //             const response = await fetch(proxyUrl + scriptUrl);
  //             const scriptContent = await response.text();
  //             console.log("in here dummy")
  //             botResponse = {
  //               text: (
  //                 <div>
  //                   <div>
  //                     <a
  //                       href={pdf_path}
  //                       onClick={(e) => openPdfInMinimizedWindow(e, pdf_path)}
  //                       target="_blank"
  //                       rel="noopener noreferrer"
  //                     >
  //                       <h2 style={{ color: '#333', textAlign: 'center' }}>{title}</h2>
  //                     </a>
  //                   </div>
  //                   <span>
  //                     <ReactMarkdown>{scriptContent}</ReactMarkdown>
  //                   </span>
  //                 </div>

  //               ),
  //             };
  //             } else {
  //               console.log("test2")
  //                 console.error('Script URL not found in the response');
  //                 botResponse = {
  //                   text: (
  //                     <div>
  //                       <div>
  //                         <a
  //                           href={pdf_path}
  //                           onClick={(e) => openPdfInMinimizedWindow(e, pdf_path)}
  //                           target="_blank"
  //                           rel="noopener noreferrer"
  //                         >
  //                           <h2 style={{ color: '#333', textAlign: 'center' }}>{title}</h2>
  //                         </a>
  //                       </div>
  //                       <span>
  //                         <div dangerouslySetInnerHTML={{ __html: asstResponse }} />
  //                       </span>
  //                     </div>
  //                   ),
  //                 };
  //             }
  //            } else {
  //             console.log("test3")
  //                console.error("Gist file not found");
  //                botResponse = {
  //                 text: (
  //                   <div>
  //                     <div>
  //                       <a
  //                         href={pdf_path}
  //                         onClick={(e) => openPdfInMinimizedWindow(e, pdf_path)}
  //                         target="_blank"
  //                         rel="noopener noreferrer"
  //                       >
  //                         <h2 style={{ color: '#333', textAlign: 'center' }}>{title}</h2>
  //                       </a>
  //                     </div>
  //                     <span>
  //                       <div dangerouslySetInnerHTML={{ __html: asstResponse }} />
  //                     </span>
  //                     <img
  //                     src={process.env.PUBLIC_URL + '/chatbot.png'}
  //                     alt="Comment"
  //                     className='comment-btn'
  //                     onClick={this.toggleFormVisibility}
  //                     // style={{float: 'right'}}
  //                   /> 
  //                     <form onSubmit={(event) => this.handleSubmit(event)} className="form-container" style={{ display: 'none' }}>
  //                     <div className="form-action">
  //                       <label htmlFor="actionStatus">Action Status:</label>
  //                       <select name="actionStatus" id="actionStatus" className="action-status-dropdown">
  //                         <option value="select-action">Select Action Status</option>
  //                         <option value="completed">Completed</option>
  //                         <option value="incomplete">Incomplete</option>
  //                       </select>
  //                     </div>
  //                     <div className="form-comment">
  //                       <label htmlFor="comment">Comment:</label>
  //                       <textarea id="comment" name="comment" className="comment-input" rows="4" cols="50" placeholder="Add your comment here..."></textarea>
  //                     </div>
  //                     <button type="submit" className="form-submit-button">Submit</button>
  //                   </form>
  //                   </div>
  //                 ),
  //               };
  //            }
  //           setMessages((prevMessages) => [...prevMessages, botResponse]);
  //           setLoading(false);
  //       } catch (error) {
  //           console.error("Error in transforming search UI component:", error);
  //           return setBotResponse("Error: Failed to process response data.");
  //       }
  //   }

  //   }

  const [circularNumberr, setCirNumber] = useState("");
  const [qaContext, setQAContext] = useState("");
  const handleSendMessage = async (message = null) => {
    // Check if message is a string before calling trim
    const textToSend = message && typeof message === 'string' ? message.trim() : inputText && typeof inputText === 'string' ? inputText.trim() : '';
    try {
      if (textToSend !== '') {
        const userMessage = { text: textToSend, isUser: true };
        setMessages((prevMessages) => [...prevMessages, userMessage]);
        setLoading(true);
        setInputText('');
        setSuggestionsVisible(false);
  
        const response = await fetch('/get_response', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_input: textToSend }),
        });
  
        console.log(response);
        console.log(textToSend);
        if (response.ok) {
          const Response_body = await response.json();
          const content = Response_body['content'];
          const content_type = Response_body['content_type'];
          console.log(content_type);
  
          const Component = UIComponentFactory(content_type);
          if (Component && typeof Component.transform === 'function') {
            Component.transform(Response_body);
          } else {
            console.error('Error: Invalid component or transform method not found');
          }
        } else if (response.status === 302) {
          // Handle redirection
          const redirectedUrl = response.headers.get('Location');
          console.log('Redirected URL:', redirectedUrl);
          // Fetch the redirected URL or handle it accordingly
        } else {
          console.error('Error:', response.statusText);
        }
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };
  
  // const handleSendMessage = async (message = null) => {
  //   const textToSend = message ? message.trim() : inputText.trim();
  //   try {
  //     if (textToSend !== '') {
  //       const userMessage = { text: textToSend, isUser: true };
  //       setMessages((prevMessages) => [...prevMessages, userMessage]);
  //       setLoading(true);
  //       setInputText('');
  //       setSuggestionsVisible(false);
  
  //       const response = await fetch('/get_response', {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({ user_input: textToSend }),
  //       });
  
  //       console.log(response);
  //       console.log(textToSend);
  //       if (response.ok) {
  //         const Response_body = await response.json();
  //         const content = Response_body['content'];
  //         const content_type = Response_body['content_type'];
  //         console.log(content_type);
  //         Component = UIComponentFactory(content_type);
  //         console.log(Component);
  //         Component.transform(Response_body);
  //       } else if (response.status === 302) {
  //         // Handle redirection
  //         const redirectedUrl = response.headers.get('Location');
  //         console.log('Redirected URL:', redirectedUrl);
  //         // Fetch the redirected URL or handle it accordingly
  //       } else {
  //         console.error('Error:', response.statusText);
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Error:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const handleSendMessage = async () => {
  //   try {
  //     if (inputText.trim() !== '') {
  //       const userMessage = { text: inputText.trim(), isUser: true };
  //       setMessages([...messages, userMessage]);
  //       setLoading(true);
  //       setInputText('');
  //       setSuggestionsVisible(false);
  //       if (inputText.trim().startsWith('/circular')) {
  //         const circularNumber = inputText.trim().substring(9);
  //         const formattedText = circularNumber;
  //         // setQAContext(formattedText.trim());
  //       } 
  //       const response = await fetch('/get_response', {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({ user_input: inputText }),
  //       });
  //       console.log(response)
  //       console.log(inputText)
  //       if (response.ok) {
  //         const Response_body = await response.json();
  //         const content = Response_body['content'];
  //         const content_type = Response_body['content_type'];
  //         console.log(content_type);
  //         Component = UIComponentFactory(content_type);
  //         console.log(Component);
  //         Component.transform(Response_body);
  //       } else if (response.status === 302) {
  //         // Handle redirection
  //         const redirectedUrl = response.headers.get('Location');
  //         console.log('Redirected URL:', redirectedUrl);
  //         // Fetch the redirected URL or handle it accordingly
  //       } else {
  //         console.error('Error:', response.statusText);
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // };


  function UIComponentFactory(content_type) {
    switch (content_type) {
      case "DzTableUiComponent":
        return new DzTableUiComponent();
        break;
      case "DzReferencesUiComponent":
        return new DzReferencesUiComponent();
        break;
      case "DzActionUiComponent":
        return new DzActionUiComponent();
        break;
      case "DzChangesUiComponent":
        return new DzChangesUiComponent();
        break;
      case "DzCircularUiComponent":
        return new DzCircularUiComponent();
        break;
      case "DzTextUiComponent":
        return new DzTextUiComponent();
        break;
      case "DzAuditorUiComponent":
        return new DzAuditorUiComponent();
        break;
      default:
        return new DzSearchUiComponent();
        break;
    }
  };

  const [pdfUrl, setPdfUrl] = useState(null);

  const openPdfViewer = (e, pdfUrl) => {
    e.preventDefault();
    setPdfUrl(pdfUrl);
  };

  const closePdfViewer = () => {
    setPdfUrl(null);
  };

  const openPdfInMinimizedWindow2 = (pdfUrl) => {
    const screenWidth = window.screen.availWidth;
    const screenHeight = window.screen.availHeight;

    const windowWidthPercentage = 60;
    const windowHeightPercentage = 60;

    const windowWidth = (screenWidth * windowWidthPercentage) / 100;
    const windowHeight = (screenHeight * windowHeightPercentage) / 100;

    const topPosition = screenHeight / 2 - windowHeight / 2;
    const leftPosition = screenWidth / 2 - windowWidth / 2;

    const newWindow = window.open(
      pdfUrl,
      "_blank",
      `width=${windowWidth}, height=${windowHeight}, top=${topPosition}, left=${leftPosition}`
    );

    if (newWindow) {
      newWindow.focus();
    }
  };


  const openPdfInMinimizedWindow = (e, pdfUrl) => {
    e.preventDefault();


    const screenWidth = window.screen.availWidth;
    const screenHeight = window.screen.availHeight;


    const windowWidthPercentage = 60;
    const windowHeightPercentage = 60;

    const windowWidth = (screenWidth * windowWidthPercentage) / 100;
    const windowHeight = (screenHeight * windowHeightPercentage) / 100;


    const topPosition = screenHeight / 2 - windowHeight / 2;
    const leftPosition = screenWidth / 2 - windowWidth / 2;

    const newWindow = window.open(
      pdfUrl,
      "_blank",
      `width=${windowWidth}, height=${windowHeight}, top=${topPosition}, left=${leftPosition}`
    );

    if (newWindow) {
      newWindow.focus();
    }
  };

  const parseDate = (dateString) => {
    if (dateString.includes('-')) {
      // Assume the date format in the CSV row is "d-m-yyyy"
      const [day, month, year] = dateString.split('-');
      return new Date(`${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`);
    } else {
      // Assume the date format is "yyyy"
      return parseInt(dateString);
    }
  };
  const handleCircularClick2 = (Pdf_url) => {
    openPdfInMinimizedWindow2(Pdf_url)
  };

  const handleCircularClick = (event, circularName, circularDate) => {
    event.preventDefault();

    fetch('/update-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ circularName, circularDate }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {

        console.log(data);
      })
      .catch(error => {
        console.error('There was a problem with your fetch operation:', error);
      });
  };

  const determineGroup = (doc) => {
    const label = doc.Nodelabel[0];

    if (label.toLowerCase().includes('circular')) {
      return 'group3';
    } else if (label.toLowerCase().includes('notification')) {
      return 'group3';
    } else if (label.toLowerCase().includes('act')) {
      return 'group2';
    } else if (label.toLowerCase().includes('direction')) {
      return 'group1';
    } else {
      return 'group0'
    }
  };

  const handleTextToSpeech = async (text) => {
    if (!isPlaying) {
      setIsPlaying(true);
      console.log(isPlaying)

      try {
        const response = await fetch('https://api.openai.com/v1/audio/speech', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer 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',
            "OpenAI-Organization": "org-EF2WIoQvY7Pt3k0ejIZzUQCS"
          },
          body: JSON.stringify({
            input: text,
            model: 'tts-1',
            voice: 'alloy',
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const contentType = response.headers.get('content-type');
        if (contentType && contentType.startsWith('audio')) {
          // Handle audio response
          const audioBlob = await response.blob();
          const audioUrl = URL.createObjectURL(audioBlob);
          const audio = new Audio(audioUrl);
          audio.play();
          audio.onended = () => setIsPlaying(false);
        } else {
          // Handle JSON response
          const data = await response.json();
          console.log('OpenAI API response:', data);
          setIsPlaying(false);
        }
      } catch (error) {
        console.error('Error occurred while converting text to speech:', error);
        setIsPlaying(false);
      }
    }
  };

  const handleRecordAndTranscribe = async () => {
    if (!isRecording) {
      setIsRecording(true);

      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const mediaRecorder = new MediaRecorder(stream);
        const chunks = [];

        mediaRecorder.addEventListener("dataavailable", event => {
          chunks.push(event.data);
        });

        mediaRecorder.addEventListener("stop", async () => {
          const blob = new Blob(chunks, { type: 'audio/wav' });


          const formData = new FormData();
          formData.append('file', blob);
          formData.append('model', 'whisper-1');

          try {
            const response = await fetch('https://api.openai.com/v1/audio/transcriptions', {
              method: 'POST',
              body: formData,
              headers: {
                'Authorization': 'Bearer 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',
              },
            });

            const data = await response.json();
            console.log("Response:", data);

            if (data && data.text) {
              // setTranscript(data.text);
              setInputText(data.text)
              setChatPlaceholder("");
              console.log("Transcription:", data.text);
            } else {
              console.log("No transcription data found in the response");
            }
          } catch (error) {
            console.error('Error transcribing speech:', error);
          }
        });

        mediaRecorder.start();
        setTimeout(() => {
          mediaRecorder.stop();
          setIsRecording(false);
        }, 5000);
      } catch (error) {
        console.error('Error starting recording:', error);
        setIsRecording(false);
      }
    }
  };

  const Graph = ({ jsonData, title, sendMessage }) => {
    console.log("title", title)
    const graphRef = useRef(null);

    useEffect(() => {

      const menu = document.querySelector(".custom-menu");
      const transformData = (data) => {
        const nodes = [];
        const edges = [];

        // Add main circular node
        if (data.circular && data.circular.length > 0) {
          nodes.push({
            id: data.circular[0].name,
            label: data.circular[0].name,
            title: `${data.circular[0].CurrSubject} - Date: ${data.circular[0].date}`,
            norm: data.circular[0].norm_name,
            date: data.circular[0].date,
            pdf_url: data.circular[0].path,
            group: 'group3',
            shape: 'dot',
            font: {
              color: 'black'
            },
            physics: false
          });
          let nodeid = 0
          // Add referred document nodes and edges
          data.referredDocuments.forEach((doc, index) => {
            const groupId = determineGroup(doc);
            const uniqueId = doc.name + nodeid++;
            nodes.push({
              id: uniqueId,
              docid: doc.name,
              label: doc.name,
              norm: doc.norm_name,
              date: doc.date,
              pdf_url: doc.path,
              title: `${doc.ReferredSubject} - Date: ${doc.date}`,
              shape: 'box',
              group: groupId,
              margin: 20,
              fixedsize: true,
            });

            let edge = {
              from: data.circular[0].name,
              to: uniqueId,
              label: data.relationship
            };

            if (data.relationship === 'refers to') {
              edge.title = doc.explanation;
            }

            edges.push(edge);
          });
        }

        return { nodes: new DataSet(nodes), edges: new DataSet(edges) };
      };

      const { nodes, edges } = transformData(jsonData);

      // Network configuration
      const data = { nodes, edges };
      const options = {
        groups: {
          group1: {
            color: { background: '#ef898e', border: 'black' },
          },
          group2: {
            color: { background: '#80f5a0', border: 'black' },
          },
          group3: {
            color: { background: '#8ed8f3', border: 'black' }
          },
          group0: {
            color: { background: '#FFFFED', border: 'black' }
          }
        },
        nodes: {
          font: {
            color: 'black',
            face: 'Roboto',
          },
          size: 40,
          borderWidth: 2,
          shadow: true,
          shapeProperties: {
            useBorderWithImage: true
          }
        },
        edges: {
          width: 2,
          shadow: true,
          color: {
            color: '#848484',
            highlight: '#848484',
            hover: '#848484',
          },
          font: {

            face: 'Roboto',
          },
          arrows: {
            to: { enabled: true, scaleFactor: 1, type: 'arrow' }
          }
        },
        layout: {
          improvedLayout: true,
          hierarchical: {
            enabled: false
          }
        },
        physics: {
          barnesHut: {
            gravitationalConstant: -50000,
            centralGravity: 0,
            springLength: 200
          },
          maxVelocity: 10,
          minVelocity: 0,
          wind: {
            x: 0,
            y: 0
          }
        },
        interaction: {
          hover: true,
          tooltipDelay: 200,
          hoverConnectedEdges: true
        }
      }; // You can add options here

      // Initialize network
      const network = new Network(graphRef.current, data, options);
      network.stabilize();

      network.on("hoverEdge", function (params) {
        var edgeId = params.edge;
        var edgeData = network.body.edges[edgeId];
        if (edgeData.options.label === 'makes changes to') {
          network.canvas.body.container.style.cursor = 'pointer';
        }
      });
      network.on("blurEdge", function () {
        network.canvas.body.container.style.cursor = 'default';
      });

      network.on("click", function (params) {
        if (params.nodes.length > 0) {
          const nodeId = params.nodes[0];
          const nodeLabel = nodes.get(nodeId).norm;
          const nodeDate = nodes.get(nodeId).date;
          const pdf_open = nodes.get(nodeId).pdf_url
          console.log(nodeLabel)
          console.log(nodeDate)
          handleNodeClick(pdf_open);
        } else if (params.edges.length > 0) {
          var edgeId = params.edges[0]; // get the id of the clicked edge
          var edge = network.body.edges[edgeId];
          var edgeLabel = edge.options.label;
          if (edgeLabel === "makes changes to") {
            var startNodeId = edge.from; // get the id of the start node
            var endNodeId = edge.to;
            var startNode = network.body.data.nodes.get(startNodeId.id); // get the start node object
            var endNode = network.body.data.nodes.get(endNodeId.id);
            var data = {
              startNode: startNode.norm,
              startNodeDate: startNode.date,
              endNode: endNode.norm,
              endNodeDate: endNode.date
            };
            console.log(data)

            // Send data to backend
            fetch('/explanation', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(data)
            })
              .then(response => response.json())
              .then(data => {
                console.log("this is different data", data);
                setModalData(data);
                setModalOpen(true);
              })
              .catch((error) => {
                console.error('Error:', error);
              });
          }
        }
      });

      network.on("oncontext", function (params) {
        params.event.preventDefault();

        // Display the context menu
        const nodeId = network.getNodeAt(params.pointer.DOM);
        const docid = nodes.get(nodeId).norm;
        const nodeDate = nodes.get(nodeId).date;
        console.log(docid, "This is the DOC id")
        // Only show the context menu if a node was clicked
        if (nodeId) {
          // Display the context menu
          handleCircularClick(params.event, docid, nodeDate);
          menu.style.display = 'block';

          // Get the bounding rectangle of the graph container
          const x = params.event.clientX;
          const y = params.event.clientY;

          // Position the context menu
          menu.style.top = `${y}px`;
          menu.style.left = `${x}px`;


          // Add your custom logic here
        }


        // Add your custom logic here
      });

      document.getElementById('menu-option1').addEventListener('click', function () {
        console.log('Option 1 clicked');
        setInputText('/references')
        // Add your custom logic here
        menu.style.display = 'none';
      });

      document.getElementById('menu-option2').addEventListener('click', function () {
        console.log('Option 2 clicked');
        setInputText('/changes')
        // Add your custom logic here
        menu.style.display = 'none';
      });

      // document.getElementById('menu-option3').addEventListener('click', function() {
      //   console.log('Option 3 clicked');
      //   setInputText('/relevance')
      //   // Add your custom logic here
      //   menu.style.display = 'none';
      // });

      document.getElementById('menu-option4').addEventListener('click', function () {
        console.log('Option 4 clicked');
        setInputText('/action')
        // Add your custom logic here
        menu.style.display = 'none';
      });

      // Hide context menu when clicking anywhere else
      document.addEventListener('click', function (event) {
        if (event.target.closest('.custom-menu') === null) {
          menu.style.display = 'none';
        }
      });


    }, [jsonData, title, sendMessage]); // Make sure to include jsonData in the dependency array

    return (
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'flex-start', height: '100%', width: '98%', transform: "none", transition: "none", overflow: "hidden" }}>
        {/* <div style={{ marginBottom: '20px', textAlign: 'left', padding: '10px', borderRadius: '5px', width: '300px' }}>
      <ul style={{marginBottom: '5px' }}>
        <li>Hover over nodes and edges for more information</li>
        <li>Right click for more information</li>
        <li>Left click to open PDF</li>
      </ul>
      </div> */}
        {title && <h2 style={{ marginBottom: '20px' }}>{title}</h2>}
        <div ref={graphRef} style={{ width: '100%', height: '600px', paddingBottom: '20px' }} />
        <div class="custom-menu">
          <p id="menu-option1">/references</p>
          <p id="menu-option2">/changes</p>
          {/* <p id="menu-option3">/relevance</p> */}
          <p id="menu-option4">/action</p>
        </div>
        {/* Legends Box */}
        <div style={{ backgroundColor: '#ffffff', padding: '10px', paddingTop: '0px', borderRadius: '5px', boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)', marginBottom: '20px', marginLeft: '20px', alignSelf: "flex-start" }}>
          <h3>Legend</h3>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
              <div style={{ width: '20px', height: '20px', backgroundColor: '#8ed8f3', border: '1px solid black', marginRight: '5px' }}></div>
              <span>Circular</span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
              <div style={{ width: '20px', height: '20px', backgroundColor: '#80f5a0', border: '1px solid black', marginRight: '5px' }}></div>
              <span>Act</span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
              <div style={{ width: '20px', height: '20px', backgroundColor: '#ef898e', border: '1px solid black', marginRight: '5px' }}></div>
              <span>Master Direction</span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ width: '20px', height: '20px', backgroundColor: '#FFFFED', border: '1px solid black', marginRight: '5px' }}></div>
              <span>Other Notifications</span>
            </div>
          </div>
        </div>
      </div>


    );
  };

  const handleNodeClick = (pdf_url) => {
    if (pdf_url) {
      openPdfInMinimizedWindow2(pdf_url);
    }
  };

  useEffect(() => {
    const autoclickElement = document.getElementById('autoclick');
    if (autoclickElement) {
      autoclickElement.click();
    }
  }, []);

  const mouseClickEvents = ['mousedown', 'click', 'mouseup'];
  function simulateMouseClick(element) {
    console.log(element.className, "Class Name mousce click")
    mouseClickEvents.forEach(mouseEventType =>
      element.dispatchEvent(
        new MouseEvent(mouseEventType, {
          view: window,
          bubbles: true,
          cancelable: true,
          buttons: 1
        })
      )
    );
  }
  // This function converts the JSON data to DOT format.
  function convertToDot(data) {
    let dot = `digraph G {
      node [shape=rectangle, color="#F0F8FF", fontname="Helvetica"];
      edge [color="#A9A9A9", fontname="Helvetica"];
      "${data.circular[0].name}" [label=<<TABLE BORDER="0" CELLBORDER="0" CELLSPACING="0"><TR><TD HREF="file:/home/ubuntu/pdf/0.pdf">${data.circular[0].name}</TD></TR></TABLE>>, style=filled, color=lightblue, tooltip="${data.circular[0].CurrSubject + ' - Date: ' + data.circular[0].date}"];\n`;

    for (let doc of data.referredDocuments) {
      dot += `    "${doc.document}" [label=<<TABLE BORDER="0" CELLBORDER="0" CELLSPACING="0"><TR><TD HREF="www.google.com">${doc.document}</TD></TR></TABLE>>, tooltip="${doc.ReferredSubject + ' - Date: ' + doc.date}", style=filled] \n`;
      dot += `    "${data.circular[0].name}" -> "${doc.document}" [label="${data.relationship}", labeltooltip="${doc.specificPart}", headtooltip="${doc.explanation}"]\n`;
    }

    dot += '}';
    return dot;
  }

  // This function sets the bot response for errors or success.
  function setBotResponse(content, isGraph = false) {
    return {
      text: (

        <div style={{
          border: '1px solid #ddd',
          borderRadius: '4px',
          overflow: 'hidden', // Use 'auto' if you want to allow scrolling
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          maxWidth: '90vw', // The graph can now go up to 90% of the viewport width
          width: '100%', // The div will take the full width of its container
          margin: '20px auto', // Centers the div on the page with some margin from the top and bottom
          padding: '10px', // Optional: adds some space inside the div, around the Graphviz component
          backgroundColor: '#fff', // Optional: sets a background color for the div that contains the graph
        }}>
          {content}
        </div>
      ),
      isGraph: isGraph,
    };
  }

  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (suggestionsVisible && selectedSuggestionIndex !== -1) {
        event.preventDefault();
        handleSuggestionClick(suggestions[selectedSuggestionIndex]);
        setSuggestionsVisible(false);
        setSelectedSuggestionIndex(-1);
      } else {
        handleSendMessage();
      }
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      setSelectedSuggestionIndex((prevIndex) => Math.max(0, prevIndex - 1));
    } else if (event.key === 'ArrowDown') {
      event.preventDefault();
      setSelectedSuggestionIndex((prevIndex) => Math.min(suggestions.length - 1, prevIndex + 1));
    }
  };
  const [isSavingChat, setIsSavingChat] = useState(false);
  const handleNewChat = async () => {
    try {
      if (messages.length > 0) {
        setIsSavingChat(true);
        let saveChatResponse = null;
        if (activeChatIndex !== null) {
          const updatedHistory = [...chatHistory];
          const updatedChat = updatedHistory[activeChatIndex];

          if (updatedChat && updatedChat.id && updatedChat.name) {
            updatedChat.messages = [...messages];
            saveChatResponse = await fetch('/save_chat', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                chatId: updatedChat.id,
                chatName: updatedChat.name,
                messages: updatedChat.messages,
              }),

            });
            console.log('Chat messages:', updatedChat.messages);
            console.log('Chat updated:', saveChatResponse);
          }
          setIsSavingChat(false);
          setChatHistory(updatedHistory);

        } else {
          const firstUserMessage = messages.find((message) => message.isUser);
          const chatName = firstUserMessage.text.split(' ').slice(0, 5).join(' ');
          const newChat = { name: chatName, messages: messages };
          saveChatResponse = await fetch('/save_chat', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ chat: messages }),
          });
          setChatHistory([newChat, ...chatHistory]);
          setIsSavingChat(false);
        }
        // setIsSavingChat(false);
        alert('Chat saved successfully!');
        const savedChatData = await saveChatResponse.json();
        if (savedChatData && savedChatData.chatId) {

          await fetch(`/get_chatnames?chatId=${savedChatData.chatId}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });
        }



        const getResponse = await fetch('/get_response', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_input: '/new' }),
        });

        const assistantMessages = await getResponse.json();


      }
      setIsSavingChat(false);
      setResponseData(null);
      setMessages([]);
      setActiveChatIndex(null);
      setChatPlaceholder(placeholderText);
    } catch (error) {
      setIsSavingChat(false);
      console.error('Error in handleNewChat:', error);
    }

  };


  useEffect(() => {
    const apiUrl = '/get_chatnames';
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {

        if (Array.isArray(data.chats)) {
          setChatHistory(data.chats);

          console.log(setChatHistory);
        } else {
          console.error('Invalid data format received from the API');
        }
      })
      .catch((error) => {
        console.error('Error fetching chat names:', error);
      });
  }, []);


  const handleChatHistoryClick = async (index) => {
    setActiveChatIndex(index);
    console.log("history method------")
    if (index >= 0 && index < chatHistory.length) {
      const selectedChat = chatHistory[index];
      const chatId = selectedChat.id;
      console.log('Selected Chat:', selectedChat);
      const chatName = selectedChat.name;
      console.log('Chat Name:', chatName);
      try {

        const response = await fetch('/get_chatnames_api', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ chatId, chatName }),
        });

        if (response.ok) {

          const secondApiResponse = await fetch('/get_messages', {
            method: 'GET',
          });

          if (secondApiResponse.ok) {
            const responseData = await secondApiResponse.json();
            console.log('Response Data from Second API:', responseData);

            setResponseData(responseData);
          } else {
            console.error('Error fetching response from the second API.');
          }
        } else {
          console.error('Error sending chat name to the first API.');
        }
      } catch (error) {
        console.error('Error handling chat history click:', error);
      }
    }
    setChatPlaceholder('');
  };

  const handleDeleteChat = async (index, event) => {
    event.stopPropagation();

    const updatedHistory = [...chatHistory];
    const chatToDelete = updatedHistory[index];

    updatedHistory.splice(index, 1);
    setChatHistory(updatedHistory);

    if (activeChatIndex === index) {
      setMessages([]);
      setActiveChatIndex(null);
      setResponseData([]);
      setChatPlaceholder(placeholderText);
    }

    try {

      const deleteChatResponse = await fetch('/delete_chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          chatname: chatToDelete.name,
          chatid: chatToDelete.id,
        }),
      });

      console.log('Chat deleted:', deleteChatResponse);

    }
    catch (error) {
      console.error('Error deleting chat:', error);
    }

  };
  const openPdfInMinimizedWindow3 = (htmlContent) => {
    const screenWidth = window.screen.availWidth;
    const screenHeight = window.screen.availHeight;

    const windowWidthPercentage = 60;
    const windowHeightPercentage = 60;

    const windowWidth = (screenWidth * windowWidthPercentage) / 100;
    const windowHeight = (screenHeight * windowHeightPercentage) / 100;

    const topPosition = screenHeight / 2 - windowHeight / 2;
    const leftPosition = screenWidth / 2 - windowWidth / 2;

    const newWindow = window.open(
      '',
      '_blank',
      `width=${windowWidth}, height=${windowHeight}, top=${topPosition}, left=${leftPosition}`
    );

    if (newWindow) {
      newWindow.focus();
      newWindow.document.write(htmlContent);
      newWindow.document.close();
    }
  };

  const handleUserOptionClick = async (option) => {
    console.log(option);
    switch (option) {
      // case 'Profile':
      //   console.log('Profile clicked');
      //   window.location.href = '/profile';
      //   break;
      case 'Logout':
      console.log('Logout clicked');
      try {
        // Fetch the authentication type
        const authTypeResponse = await fetch('/logout_type', {
          method: 'POST',
          credentials: 'include',
        });
        if (!authTypeResponse.ok) {
          throw new Error('Failed to get authentication type');
        }
        
        const authTypeData = await authTypeResponse.json();
        const authType = authTypeData.authentication_type;
        
        // Determine the logout URL based on the authentication type
        let logoutUrl;
        if (authType === 'Microsoft') {
          logoutUrl = '/microsoft/logout';
        } else if (authType === 'Google') {
          logoutUrl = '/google/logout';
        } else {
          throw new Error('Unknown authentication type');
        }
        
        // Perform the logout
        const response = await fetch(logoutUrl, {
          method: 'GET',
          credentials: 'include',
        });
        if (response.ok) {
          window.location.href = '/';
        } else {
          console.error('Logout failed:', response);
        }
      } catch (error) {
        console.error('There was an error logging out', error);
      }
      break;
      case 'Generate Token':
        console.log('Generate Token clicked');
        fetch('/generate_token', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({})
        })
          .then(response => response.json())
          .then(data => {
            const token = data.token;
            const htmlContent = `
              <!DOCTYPE html>
              <html lang="en">
              <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>Token</title>
                <style>
                  body {
                    font-family: Arial, sans-serif;
                    margin: 0;
                    padding: 20px;
                    background-color: #f8f9fa;
                  }
                  h1 {
                    margin-top: 0;
                  }
                </style>
              </head>
              <body>
                <h1>Token</h1>
                <p>${token}</p>
                <p>Please copy this token as it won't be visible afterwards.</p>
              </body>
              </html>
            `;
            openPdfInMinimizedWindow3(htmlContent);
          })
          .catch(error => {
            console.error('Failed to generate token:', error);
          });
        break;
      case 'Multiple Regulator':
        setIsUserProfileDropdownOpen(false);
        setShowRegulatorForm(true);
        break;
      case 'Prompt':
        console.log('Prompt mode clicked');
        setIsDarkMode(true)
        break;

      case 'Browse':
        console.log('Browse mode clicked');
        setIsDarkMode(false);
        break;

      case 'Multiple Regulator':
        setIsUserProfileDropdownOpen(false);
        setShowRegulatorForm(true);
        break;

      default:
        console.log('Default case');
        break;
    }
  };

  const toggleDarkMode = (mode) => {
    if (mode === 'browse') {
      setIsDarkMode(true);
    } else if (mode === 'prompt') {
      setIsDarkMode(false);
    }
  };


  const handleDocumentClick = (event) => {
    if (event.target.closest('.user-profile-container') === null) {
      setIsUserProfileDropdownOpen(false);
    }
  };

  document.addEventListener('click', handleDocumentClick);

  const handleComponentUnmount = () => {
    document.removeEventListener('click', handleDocumentClick);
  };

  useEffect(() => {
    return handleComponentUnmount;
  }, []);


  const handleDocumentClick1 = (event) => {
    if (!inputRef.current.contains(event.target)) {
      setSuggestionsVisible(false);
    }
  };

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // const handleStartDateChange = date => {
  //   setStartDate(date);
  // };
  const handleStartDateChange = (date) => {
    setStartDate(date);
    if (!endDate) {
      setEndDate(new Date());
    }
  };
  const handleEndDateChange = date => {
    setEndDate(date);
  };
  // Add event listener for click events on the document object
  // useEffect(() => {
  //   document.addEventListener('click', handleDocumentClick1);
  //   return () => {
  //     document.removeEventListener('click', handleDocumentClick1);
  //   };
  // }, []);


  // Function to handle input box clicks
  const handleInputClick = () => {
    setSuggestionsVisible(true);
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  const handleLogout = () => {
    //localStorage.removeItem('userToken');
    console.log("button clicked")
    window.location.href = '/logout';
  };

  const toggleLogout = () => {
    setIsLogoutVisible(!isLogoutVisible);
  };



  // const toggleDarkMode = () => {
  //   setIsDarkMode(!isDarkMode);
  // };

  const handleSuggestionClick = (suggestion) => {
    setInputText(suggestion);
    setSuggestionsVisible(false);
    suggestionIndexRef.current = -1;

  };

  const [userDetails, setUserDetails] = useState(null);
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get('/dz/assistant/users/details');
        const data = response.data;
        setUserDetails(data);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };
    fetchUserDetails();
  }, []);

  // const [regulator, setRegulator] = useState('');
  // const [circularCount, setCircularCount] = useState(0);
  // const [masterCircularCount, setMasterCircularCount] = useState(0);
  // const [masterDirectionCount, setMasterDirectionCount] = useState(0);
  // const [latestCircular, setLatestCircular] = useState('');
  // const [latestCircularDate, setLatestCircularDate] = useState('');

  // useEffect(() => {
  //   async function fetchRegulator() {
  //     try {
  //       const response = await axios.get('/get_regulator');
  //       const data = response.data;
  //       setRegulator(data.regulator);
  //       setCircularCount(data.metadata['Circulars']['count']);
  //       setMasterCircularCount(data.metadata['Master Circulars']['count']);
  //       setMasterDirectionCount(data.metadata['Master Directions']['count']);
  //       setLatestCircular(data.metadata['Latest Circular']);
  //       setLatestCircularDate(data.metadata['Latest Circular Date']);
  //     } catch (error) {
  //       console.error('Error fetching regulator:', error);
  //     }
  //   }
  //   fetchRegulator();
  // }, []);

  const [regulator, setRegulator] = useState('');
  const [circularCount, setCircularCount] = useState(0);
  const [masterCircularCount, setMasterCircularCount] = useState(0);
  const [masterDirectionCount, setMasterDirectionCount] = useState(0);
  const [latestCircular, setLatestCircular] = useState('');
  const [latestCircularDate, setLatestCircularDate] = useState('');
  const [metadata, setMetadata] = useState({});

  useEffect(() => {
    async function fetchRegulator() {
      try {
        const response = await axios.get('/get_regulator');
        const data = response.data;
        setRegulator(data.regulator);
        setMetadata(data.metadata);
        setQAContext(`${data.regulator} all documents`)
      } catch (error) {
        console.error('Error fetching regulator:', error);
      }
    }
    fetchRegulator();
  }, []);
  const [showModal, setShowModal] = useState(false);
  // const renderMetadataRows = () => {
  //   const keys = Object.keys(metadata);
  //   const rows = [];
  //   let i = 0;
  //   const firstRowItems = keys.slice(0, 2).map((key) => (
  //     <div key={key} className="metadata-pair">
  //       <span className="key">{key.replace(/_/g, ' ')}:</span>
  //       <span className="value">
  //         {typeof metadata[key] === 'object' ? metadata[key].count : metadata[key]}
  //       </span>
  //     </div>
  //   ));
  //   rows.push(
  //     <div key="first-row" className="row">
  //       <div className="metadata-pair">
  //         <span className="key">Regulator:</span>
  //         <span className="value">{regulator}</span>
  //       </div>
  //       {firstRowItems}
  //     </div>
  //   );
  //   i = 2; 

  //   for (; i < keys.length; i += 3) {
  //     const rowItems = keys.slice(i, i + 3).map((key) => (
  //       <div key={key} className="metadata-pair">
  //         <span className="key">{key.replace(/_/g, ' ')}:</span>
  //         <span className="value">
  //           {typeof metadata[key] === 'object' ? metadata[key].count : metadata[key]}
  //         </span>
  //       </div>
  //     ));
  //     rows.push(<div key={i} className="row">{rowItems}</div>);
  //   }
  //   return rows;
  // };

  const renderMetadataRows = () => {
    return (
      <div className="row">
        <div className="metadata-pair">
          <span className="key">Regulator:</span>
          <span className="value">{regulator}</span>
        </div>
        <div>
          <span className="key">QA context:</span>
          <span className="value">{qaContext}</span>
        </div>
        <FaInfoCircle className="info-icon" onClick={() => setShowModal(true)} />
      </div>
    );
  };

  const renderModalContent = () => {
    const keys = Object.keys(metadata);
    return keys.map((key, index) => (
      <div key={index} className="metadata-pair">
        <span className="key">{key.replace(/_/g, ' ')}:</span>
        <span className="value">
          {typeof metadata[key] === 'object' ? metadata[key].count : metadata[key]}
        </span>
      </div>
    ));
  };


  return (

    <div>
      {isErrorModalOpen && (
        <div className="modal" id="errorModal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            <p id="errorMessage">{errorMessage}</p>
          </div>
        </div>
      )}
      <div className={`chat-container ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
        <button
          className={`hamburger-button ${isMobileMenuOpen ? 'open' : ''}`}
          onClick={toggleMobileMenu}
        >
          <div className="hamburger-icon"></div>
          <div className="hamburger-icon"></div>
          <div className="hamburger-icon"></div>
        </button>
        {isMobileMenuOpen && (
          <MobileMenu
            chatHistory={chatHistory}
            isOpen={isMobileMenuOpen}
            toggleMenu={toggleMobileMenu}
            handleChatHistoryClick={handleChatHistoryClick}
            activeChatIndex={activeChatIndex}
            handleDeleteChat={handleDeleteChat}
          />
        )}
        <button
          className={`hamburger-buttonn ${isSidebarOpen ? 'open' : ''}`}
          onClick={() => {
            console.log("Hamburger button clicked");
            toggleSidebar();
          }}
        >
          <div className="hamburger-iconn"></div>
          <div className="hamburger-iconn"></div>
          <div className="hamburger-iconn"></div>
        </button>
        <a href="https://complieaze.ai" target="_blank" rel="noopener noreferrer">
          <div className="logo">
            <span style={{ color: "#4FC2C9" }}>Compli</span>
            <span style={{ color: "#000" }}>eaze</span>
          </div>
        </a>
        <h2 className="titlee">Regulatory compliance assistant</h2>
        <div className={`sidebar ${isDarkMode ? 'dark-mode' : 'light-mode'} ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
          {!isDarkMode ? (
            <>
              <div className="sidebar-header">
                <div className="recent-chat-label">Recent Chat</div>
                <div className="divider-container">
                  <hr className="divider-below-recent-chat" />
                </div>

                <div className="chat-history">
                  {chatHistory.map((chat, index) => (
                    <div
                      key={index}
                      className={`chat-history-entry ${index === activeChatIndex ? 'active' : ''}`}
                      onClick={() => handleChatHistoryClick(index)}>
                      {chat.name}

                      {index === activeChatIndex && (
                        <button className="delete-button" onClick={(event) => handleDeleteChat(index, event)}>
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : (
            <>
              {/* Browse mode sidebar content */}
              <div className="sidebar-header">
                <div className="browse-chat-label">Browse documents</div>
                <div className="divider-container">
                  <hr className="divider-below-recent-chat" />
                </div>
              </div>
            </>
          )}
        </div>
        <div className="bottom-left-section">
          <p className="bottom-left-text">A product by</p>
          <a href="https://www.dataeaze.io/" target="_blank">
            <img src="dataease-logo.png" alt="Product Image" class="bottom-left-logo" />
          </a>
        </div>

        <div className="user-profile-container">
          {userDetails && (
            <div className='message-container'>
              {userDetails.first_name && userDetails.last_name ? (
                <div className='welcome-message-container'>
                  <p>
                    Welcome <strong>{userDetails.first_name} {userDetails.last_name}</strong> to <em>Complieaze</em>! How may I assist you?
                  </p>
                </div>
              ) : (
                <div className='welcome-message-container'>
                  <p>
                    Welcome to <em>Complieaze</em>! How may I assist You?
                  </p>
                </div>
              )}
            </div>
          )}
          <button id="profile-btn" className="user-profile-button" type="button" onClick={toggleUserProfileDropdown}>
            <div className="user-profile-image">
              <img src={process.env.PUBLIC_URL + '/user-3-line.png'} alt="User Profile" />
            </div>
          </button>
          {isUserProfileDropdownOpen && (
            <div className="user-profile-dropdown">
              <div onClick={() => handleUserOptionClick('Logout')}>Logout</div>
              <div onClick={() => handleUserOptionClick('Generate Token')}>Generate Token</div>
              <div onClick={() => handleUserOptionClick('Multiple Regulator')}>Multiple Regulator</div>
              <div onClick={() => toggleDarkMode('prompt')}>Prompt</div>
              <div onClick={() => toggleDarkMode('browse')}>Browse</div>
              {/* <div onClick={() => handleUserOptionClick('Sidebar Toggle')}>Sidebar Toggle</div> */}
            </div>
          )}
          {showRegulatorForm && (
            <div className="form-containerrr">
              <IconButton className="close-button" onClick={handleCloseForm}>
                <CloseIcon />
              </IconButton>
              <div className="form-group">
                <span className="key">Regulator:</span>
                <FormControl variant="outlined" size="small" style={{ minWidth: 160, border: 'none' }}>
                  <InputLabel>Regulator</InputLabel>
                  <Select
                    multiple
                    value={selectedRegulator}
                    onChange={handleRegulatorChange}
                    input={<OutlinedInput label="Regulator" style={{ border: 'none' }} />}
                    renderValue={(selected) => selected.join(', ')}
                  >
                    {regulators.map((regulator) => (
                      <MenuItem key={regulator} value={regulator}>
                        <Checkbox checked={selectedRegulator.indexOf(regulator) > -1} />
                        <ListItemText primary={regulator} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <Button className="submit-button" variant="contained" color="primary" onClick={handleSubmit}>
                Submit
              </Button>
              {isFormLoading && (
                <div className="form-loader-container">
                  <div className="formloader"></div>
                </div>
              )}
            </div>
          )}
        </div>
        <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)} data={modalData} />
        <div className={`chat-content ${isDarkMode ? 'dark-mode' : 'light-mode'} ${isSidebarOpen ? '' : 'sidebar-closed'} ${isSavingChat ? 'saving' : ''}`}>
          {!isDarkMode ? (
            <>
              <div className="header-buttons">
                <button className="clear-chat-button" onClick={() => { setResponseData([]); setMessages([]); }}>
                  Clear Chat
                </button>
                <button id="newchat" className="new-chat-button" onClick={handleNewChat}>
                  New Chat
                </button>
              </div>
              {/* {isSavingChat && (
        <div className="loader-containerr">
          <div className="loaderr"></div>
        </div>
      )} */}
              {/* <div className="header-text">
          <div className="ask-question-text">{regulator}</div>
        </div> */}
              {/* 
              <div className="header-text">
                <div className="row-one">
                  <div className="regulator-pair">
                    <span className="key">Regulator:</span>
                    <span className="value">{regulator}</span>
                  </div>
                  <div className="circulars-pair">
                    <span className="key">Circulars count:</span>
                    <span className="value">{circularCount}</span>
                  </div>
                  <div className="master-pair">
                    <span className="key">Master Circulars count:</span>
                    <span className="value">{masterCircularCount}</span>
                  </div>
                </div>
                <div className="row-two">
                  <div className="master-directions-pair">
                    <span className="key">Master directions count:</span>
                    <span className="value">{masterDirectionCount}</span>
                  </div>
                  <div className="latest-circular-pair">
                    <span className="key">Latest circular:</span>
                    <span className="value">{latestCircular}</span>
                  </div>
                  <div className="latest-circular-date-pair">
                    <span className="key">Latest circular date:</span>
                    <span className="value">{latestCircularDate}</span>
                  </div>
                </div>
                <div className="row-three">
                  <div className="circular-number-pair">
                    <span className="key">QA context:</span>
                    <span className="value">{qaContext}</span>
                  </div>
                </div>
              </div> */}
              {/* <div className="header-text">
      {renderMetadataRows()}
      <div className="row-three">
                  <div className="circular-number-pair">
                    <span className="key">QA context:</span>
                    <span className="value">{qaContext}</span>
                  </div>
                </div>
    </div> */}
              <div className="header-text">
                {renderMetadataRows()}
                {showModal && (
                  <div className="metadatamodal">
                    <div className="metadatamodal-content">
                      <span className="metadataclose" onClick={() => setShowModal(false)}>
                        &times;
                      </span>
                      {renderModalContent()}
                    </div>
                  </div>
                )}
              </div>
              <hr className="divider" />
              <div className="chat-messages" ref={chatContainerRef}>
                <div className={`chat-placeholder ${chatPlaceholder ? 'visible' : ''}`}>
                  {chatPlaceholder}
                </div>
                {/* <div className={`chat-placeholder ${suggestionsVisible ? 'visible' : ''}`}>
        <placeholderText handleSendMessage={handleSendMessage} />
      </div> */}
                <div className="chat-history-response">
                  {responseData && responseData.messages && responseData.messages.length > 0 ? (
                    responseData.messages.map((messageGroup, groupIndex) => (
                      <div key={groupIndex}>
                        {messageGroup.chat.map((message, index) => (
                          <div key={index} className={`message ${message.isUser ? 'user-message' : 'bot-message'}`}>
                            <div className={`message-content ${message.isUser ? 'user-content' : 'bot-content'}`}>
                              {message.isUser ? (
                                <div className="user-icon">
                                  <img src="/user-3-black.png" alt="User" />
                                </div>
                              ) : (
                                <div className="bot-icon">
                                  <img src="bot-removebg-preview.png" alt="Bot" />
                                </div>
                              )}
                              <div className={`message-text ${message.isUser ? 'user-text' : 'bot-text'}`}>
                                {message.isUser ?
                                  message.text
                                  :
                                  message.text.type === 'span' ? (
                                    <div
                                      // dangerouslySetInnerHTML={{ __html: message.text.props.children[1].props.dangerouslySetInnerHTML.__html }}
                                      dangerouslySetInnerHTML={{
                                        __html: message.text.props.children[1]?.props.dangerouslySetInnerHTML?.__html || 
                                                message.text.props.children[0]?.props.dangerouslySetInnerHTML?.__html || 
                                                ''
                                      }}
                                    />
                                  ) : (
                                    message.text
                                  )
                                }
                              </div>
                            </div>
                            {message.isUser ? null : <hr className={`message-divider ${message.isUser ? 'user-divider' : 'bot-divider'}`} />}
                          </div>
                        ))}
                      </div>
                    ))
                  ) : (
                    <div></div>
                  )}
                </div>
                {messages.map((message, index) => (
                  <div
                    key={index}
                    className={`message ${message.isUser ? 'user-message' : 'bot-message'}`}
                  >
                    <div className={`message-content ${message.isUser ? 'user-content' : 'bot-content'}`}>
                      {message.isUser ? (
                        <div className="user-icon">
                          <img src="/user-3-black.png" alt="User" />
                        </div>
                      ) : (
                        <div className="bot-icon">
                          <img src="bot-removebg-preview.png" alt="User" />
                        </div>
                      )}
                      <div className={`message-text ${message.isUser ? 'user-text' : 'bot-text'}`}>
                        {message.isUser ? (
                          message.text
                        ) : (
                          <>
                            <div className="bot-response">
                              {message.text}
                            </div>
                            <FontAwesomeIcon
                              icon={isPlaying ? faCircleNotch : faVolumeUp}
                              spin={isPlaying}
                              onClick={() => {
                                const extractTextContent = (element) => {
                                  if (typeof element === 'string') {
                                    return element;
                                  } else if (Array.isArray(element)) {
                                    return element.map(extractTextContent).join(' ');
                                  } else if (element.props && element.props.children) {
                                    return extractTextContent(element.props.children);
                                  } else if (element.props && element.props.dangerouslySetInnerHTML) {
                                    return element.props.dangerouslySetInnerHTML.__html;
                                  } else {
                                    return '';
                                  }
                                };

                                const textContent = extractTextContent(message.text);
                                handleTextToSpeech(textContent);
                              }}
                              style={{ marginLeft: '5px', cursor: 'pointer', opacity: isPlaying ? 0.5 : 1 }}
                              disabled={isPlaying}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    {message.isUser ? null : <hr className={`message-divider ${message.isUser ? 'user-divider' : 'bot-divider'}`} />}
                  </div>
                ))}
                <div ref={scrollToBottom}></div>
              </div>
              {isLoading && (
                <div className="loader-container">
                  <div className="loader"></div>
                </div>
              )}
              <div className="input-box-container">
                <button
                  onClick={handleRecordAndTranscribe}
                  disabled={isRecording}
                  className="microphone-button"
                >
                  <FontAwesomeIcon icon={isRecording ? faCircleNotch : faMicrophone} spin={isRecording} />
                </button>
                <input
                  ref={inputRef}
                  type="text"
                  value={inputText}
                  onChange={handleInputChange}
                  onKeyDown={handleInputKeyDown}
                  placeholder="Enter your query"
                  className="input-box"
                />
                <div className={`suggestions-container ${suggestionsVisible ? 'visible' : ''}`}>
                  {suggestionsVisible && (
                    <ul className="suggestions-list">
                      {suggestions.map((suggestion, index) => (
                        <li
                          key={index}
                          className={`suggestion-item ${index === selectedSuggestionIndex ? 'selected-suggestion' : ''}`}
                          onClick={() => handleSuggestionClick(suggestion)}
                        >
                          {suggestion}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <button id="autoclick" onClick={handleSendMessage} className="send-button">
                  <div className="send-button-image">
                    <img src={process.env.PUBLIC_URL + '/paper-plane.png'} alt="User Profile" />
                  </div>
                </button>
              </div>

            </>
          ) : (
            <>
              {/* <div className="header-buttons">
              </div> */}
              <div className="browse-header-text">
                {renderMetadataRows()}
                {showModal && (
                  <div className="metadatamodal">
                    <div className="metadatamodal-content">
                      <span className="metadataclose" onClick={() => setShowModal(false)}>
                        &times;
                      </span>
                      {renderModalContent()}
                    </div>
                  </div>
                )}
              </div>
              {/* <div className="browse-header-text">
                <div className="row-one">
                  <div className="regulator-pair">
                    <span className="key">Regulator:</span>
                    <span className="value">{regulator}</span>
                  </div>
                  <div className="circulars-pair">
                    <span className="key">Circulars count:</span>
                    <span className="value">{circularCount}</span>
                  </div>
                  <div className="master-pair">
                    <span className="key">Master Circulars count:</span>
                    <span className="value">{masterCircularCount}</span>
                  </div>
                </div>
                <div className="row-two">
                  <div className="master-directions-pair">
                    <span className="key">Master directions count:</span>
                    <span className="value">{masterDirectionCount}</span>
                  </div>
                  <div className="latest-circular-pair">
                    <span className="key">Latest circular:</span>
                    <span className="value">{latestCircular}</span>
                  </div>
                  <div className="latest-circular-date-pair">
                    <span className="key">Latest circular date:</span>
                    <span className="value">{latestCircularDate}</span>
                  </div>
                </div>
                <div className="row-three">
                  <div className="circular-number-pair">
                    <span className="key">Browse Documents...</span>
                  </div>
                </div>
              </div> */}

              <hr className="browse-divider" />
              <div className="browse-mode-content">
                <div className="dropdown-container">
                  <select className="browse-dropdown" onChange={handleFirstDropdownChange} value={selectedTopic}>
                    <option value="">Select Topic</option>
                    <option value="master_circular">Tax</option>
                    <option value="circulars">Banking</option>
                    <option value="master_direction"> Payment</option>
                  </select>
                  <div className="date-picker-container">
                    <DatePicker
                      selected={startDate}
                      onChange={handleStartDateChange}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Start Date"
                      className="date-picker"
                    />
                    <DatePicker
                      selected={endDate}
                      onChange={handleEndDateChange}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="End Date"
                      className="date-picker"
                    />
                  </div>
                </div>
                <div className="dropdown-container">
                  <select className="browsee-dropdown" onChange={handleSecondDropdownChange} value={selectedOption}>
                    <option value="Context">Context</option>
                    <option value="Title">Title</option>
                    <option value="Circular Number">Circular Number</option>
                  </select>
                  <input
                    type="text"
                    placeholder="Enter your input"
                    className="inputt-box"
                    value={browseInputText}
                    onChange={handleBrowseInputChange}
                  />
                </div>
                <button onClick={handleBrowseSubmit} className="submitt-button">
                  Submit
                </button>
                {/* <button onClick={() => setViewMode("graph")} className="graph-button">
        Show Similarity Graph
      </button> */}
                {isBrowseLoading && (
                  <div className="browse-loader-container">
                    <div className="loader"></div>
                  </div>
                )}
                {!isBrowseLoading && noDataMessage && (
                  <div className="no-data-message">{noDataMessage}</div>
                )}
                {viewMode === "list" && apiResponse.length > 0 && (
                  <div className="table-container1">
                    <div style={{ height: 385, width: "1086px" }}>
                      <DataGrid
                        rows={currentData}
                        columns={apiResponseColumns.map((column) => {
                          if (column.field === 'circularNumber') {
                            return {
                              ...column,
                              renderCell: (params) => (
                                <span onClick={() => handleCircularNumberClick(params.value)}
                                  style={{ cursor: 'pointer', color: 'blue' }}
                                >
                                  {params.value}
                                </span>
                              ),
                            };
                          }
                          return column;
                        })}
                        pageSize={rowsPerPage}
                        pagination={true}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        rowCount={totalDataCount}
                        getRowId={(row) => row.serialNumber}
                        onPageChange={(params) => setCurrentPage(params.page)}
                        onPageSizeChange={(params) => setRowsPerPage(params.pageSize)}
                        // onRowClick={(params) => { }}
                        components={{
                          Toolbar: () => (
                            <div style={{ padding: "10px" }}>
                              <GridToolbarExport />
                            </div>
                          ),
                        }}
                      />
                    </div>
                  </div>
                )}
                {/* {viewMode === "list" && apiResponse.length > 0 && (
                  <button onClick={() => setViewMode("graph")} className="graph-button">
                    Show Similarity Graph
                  </button>
                )} */}
                {viewMode === "graph" && apiResponse.length > 0 && (
                  <div>
                    <div className="toggle-button-container">
                      <span className="toggle-label">2D</span>
                      <div className="toggle-button-wrapper">
                        <button
                          className={`toggle-button ${dimension === "2D" ? "toggle-left" : "toggle-right"}`}
                          onClick={toggleDimension}
                        >
                          <span className="toggle-button-slider"></span>
                        </button>
                      </div>
                      <span className="toggle-label">3D</span>
                    </div>
                    <div className="graph-container">
                      {dimension === "2D" ? (
                        <Plot
                          data={[
                            {
                              type: "scatter",
                              mode: "markers",
                              x: graphData.embeddings2D.map((point) => point[0]),
                              y: graphData.embeddings2D.map((point) => point[1]),
                              text: apiResponse.map((doc) => doc.title),
                              marker: { size: 4 },
                            },
                          ]}
                          layout={graphLayout2D}
                          style={{ width: "300px", height: "250px" }}
                          className="plotly-graph"
                        />
                      ) : (
                        <Plot
                          data={[
                            {
                              type: "scatter3d",
                              mode: "markers",
                              x: graphData.embeddings3D.map((point) => point[0]),
                              y: graphData.embeddings3D.map((point) => point[1]),
                              z: graphData.embeddings3D.map((point) => point[2]),
                              text: apiResponse.map((doc) => doc.title),
                              marker: { size: 4 },
                            },
                          ]}
                          layout={graphLayout3D}
                          style={{ width: "300px", height: "250px" }}
                          className="plotly-graph"
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>

        {isSavingChat && (
          <div className="loader-containerr">
            <div className="loaderr"></div>
          </div>
        )}
        <div className="bottom-left-secton">
          <a href="https://aws.amazon.com/what-is-cloud-computing" target="_blank" rel="noopener noreferrer">
            <img src="https://d0.awsstatic.com/logos/powered-by-aws.png" alt="Powered by AWS Cloud Computing" className="bottom-left-imge" />
          </a>
        </div>

      </div>
    </div>

  );

};
export default ChatUI;
