import React, { useRef, useEffect, useState } from "react";
import NavbarComponent from "./Navbar";
import "./Landing_Page.css";
import overloadImage from "../../assets/overloadImage.jpg";
import largeUnstructured from "../../assets/largeUnstructured.jpg";
import assistant from "../../assets/assistant.svg";
import consultant from "../../assets/consultant.svg";
import analyst from "../../assets/analyst.svg";
import detection from "../../assets/ml1.png";
import telecom from "../../assets/ml2.png";
import security from "../../assets/ml3.png";
import safety from "../../assets/safety.jpg";
import geospatial from "../../assets/ml5.png";
import tutor from "../../assets/tutot.png";
import custom from "../../assets/custom.png";
import ecosystem from "../../assets/ecosystem.png";
import navigation from "../../assets/navigation.png";
import guard from "../../assets/guard.png";
import Footer from "./Footer";
import { useMediaQuery } from "react-responsive";
import MobileLandingPage from "./MobileLanding_Page";
import axios from "axios";

function LandingPage() {
  const [activeCard, setActiveCard] = useState(1);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [formData, setFormData] = useState({
    name: "",
    contactNumber: "",
    email: "",
    companyName: "",
    country: "",
    lookingFor: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "/submit-form",
        formData,
        { headers: { "Content-Type": "application/json" } }
      );

      console.log(response.data);

      setFormData({
        name: "",
        contactNumber: "",
        email: "",
        companyName: "",
        country: "",
        lookingFor: "",
        message: "",
      });

      alert("Form submitted successfully!");
    } catch (error) {
      console.error("Error submitting form:", error.message);
      alert("Failed to submit form. Please try again later.");
    }
  };
  const cardData = [
    {
      id: 1,
      title: "Deep Learning Powered Intrusion Detection",
      content:
        "Detecting events like intrusions with computer vision and deep learning applied to fiber optics signals.",
      image: detection,
    },
    {
      id: 2,
      title: "Machine Learning for Telecom",
      content:
        "Detecting events like intrusions with computer vision and deep learning applied to fiber optics signals.",
      image: telecom,
    },
    {
      id: 3,
      title: "Computer Vision for security of personnel",
      content:
        "AI on edge solution for security with person reidentification, multi camera tracking, real time alerting on security incidents.",
      image: security,
    },
    {
      id: 4,
      title: "Computer Vision for worker safety",
      content:
        "Computer vision based application tailored for industrial safety - monitoring danger zones, security compliance, alerting on accident prone objects like forklifts.",
      image: safety,
    },
    {
      id: 5,
      title: "Deep Learning for geospatial imagery",
      content:
        "Deep learning for geo-spatial data for applications like accident risk prediction, encroachment detection using geo-spatial data.",
      image: geospatial,
    },
  ];
  const videoRef = useRef(null);
  // const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  useEffect(() => {
    const handleVideoEnded = () => {
      if (videoRef.current) {
        videoRef.current.play();
      }
    };

    const handleCanPlayThrough = () => {
      if (videoRef.current) {
        videoRef.current.play();
      }
    };

    if (videoRef.current) {
      videoRef.current.addEventListener('ended', handleVideoEnded);
      videoRef.current.addEventListener('canplaythrough', handleCanPlayThrough);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('ended', handleVideoEnded);
        videoRef.current.removeEventListener('canplaythrough', handleCanPlayThrough);
      }
    };
  }, []);
  //   useEffect(() => {
  //     const options = {
  //       root: null,
  //       rootMargin: "0px",
  //       threshold: 0.5, 
  //     };

  //     const observer = new IntersectionObserver((entries) => {
  //       entries.forEach((entry) => {
  //         if (entry.isIntersecting) {
  //           setIsVideoPlaying(true);
  //         } else {
  //           setIsVideoPlaying(false);
  //         }
  //       });
  //     }, options);

  //   //   if (videoRef.current) {
  //   //     observer.observe(videoRef.current);
  //   //   }

  //   //   return () => {
  //   //     if (videoRef.current) {
  //   //       observer.unobserve(videoRef.current);
  //   //     }
  //   //   };
  //   // }, [videoRef]);
  //   const handleVideoEnded = () => {
  //     if (videoRef.current) {
  //       videoRef.current.play();
  //     }
  //   };

  //   if (videoRef.current) {
  //     observer.observe(videoRef.current);
  //     videoRef.current.addEventListener('ended', handleVideoEnded);
  //   }

  //   return () => {
  //     if (videoRef.current) {
  //       observer.unobserve(videoRef.current);
  //       videoRef.current.removeEventListener('ended', handleVideoEnded);
  //     }
  //   };
  // }, [videoRef]);

  const handleCardClick = (cardId) => {
    setActiveCard(cardId === activeCard ? null : cardId);
  };
  const handleDemoButtonClick = () => {
    window.location.href = "/microsoft/login";
  };

  return (
    <div className="LandingPage">
      {isMobile ? (
        <MobileLandingPage />
      ) : (
        <>
          <NavbarComponent />
          <section id="home" className="home-section">
            <h1 className="homeText">
              A GenAI assistant to make regulatory compliance easy.
              <button
                className="demo-buttonApp"
                onClick={() => handleDemoButtonClick()}
              >
                Get a Demo
              </button>
            </h1>
            <div className="col-12 cardProperty">
              <div className="col-4 cardView">
                <p className="lightText col-1">With complieaze</p>
                <div className="cardDarktext col-3">
                  Save time in search of regulatory compliance documents by 80%
                </div>
              </div>
              <div className="col-4 cardView">
                <p className="lightText col-1">With complieaze</p>
                <div className="cardDarktext col-3">
                  Get 360 degree summary of a given compliance document
                </div>
              </div>
              <div className="col-4 cardView">
                <p className="lightText col-1">With complieaze</p>
                <div className="cardDarktext col-3">
                  Access and analyse thousands of compliance circulars and
                  documents
                </div>
              </div>
            </div>
          </section>
          <section id="feature" className="feature-section">
            <h1 className="featureHead col-12">
              <span className="col-6">Working with Compliance</span>
              <span className="col-6 docflex">
                <span>Documents is</span>
                <span className="complexColor">Complex</span>
              </span>
            </h1>
            <div className="col-12 featureCard">
              <div
                className="col-6"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <div
                  className="col-3 lineH"
                  style={{
                    backgroundColor: "#F7FAFF",
                    padding: "2%",
                  }}
                >
                  <span className="complexColor" style={{ fontSize: "30px" }}>
                    Work overloaded
                  </span>
                  <h1 style={{ fontSize: "30px" }}>compliance officers</h1>
                  <span
                    style={{
                      inlineSize: "289px",
                      display: "flex",
                      lineHeight: "1",
                    }}
                  >
                    Vast and ever-changing nature regulations have always been a
                    risk for businesses.
                  </span>
                  <br />
                  <span
                    style={{
                      inlineSize: "289px",
                      display: "flex",
                      lineHeight: "1",
                    }}
                  >
                    Compliance departments, consultants and experts are
                    overloaded with information.
                  </span>
                </div>
                <div className="col-3">
                  <img
                    src={overloadImage}
                    loading="lazy"
                    alt="Logo"
                    style={{
                      height: "304px",
                      width: "350px",
                    }}
                  />
                </div>
              </div>
              <div
                className="col-6"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <div className="col-3">
                  <img
                    src={largeUnstructured}
                    alt="Logo"
                    loading="lazy"
                    style={{
                      height: "304px",
                      width: "350px",
                    }}
                  />
                </div>
                <div
                  className="col-3"
                  style={{
                    backgroundColor: "#F7FAFF",
                    padding: "2%",
                  }}
                >
                  <div style={{ inlineSize: "310px" }}>
                    <span style={{ fontSize: "30px" }}>
                      Huge piles of documents.
                    </span>
                    <span className="complexColor" style={{ fontSize: "30px" }}>
                      Unstructured text data is not easily queryable
                    </span>
                  </div>
                  <span
                    style={{
                      inlineSize: "289px",
                      display: "flex",
                      lineHeight: "1",
                      marginTop: "3%",
                    }}
                  >
                    Making sense of the rules and regulations and their
                    implications requires natural language understanding.
                  </span>
                </div>
              </div>
            </div>
            <div
              style={{
                textAlign: "center",
                fontSize: "40px",
                fontWeight: "600",
                fontFamily: "Zoho Puvi",
                marginTop: "6%",
              }}
            >
              <span className="complexColor" style={{ display: "block" }}>
                We built Complieaze to simplify
              </span>
              <span className="complexColor" style={{ display: "block" }}>
                regulatory compliance management
              </span>
            </div>
            <div
              style={{
                display: "block",
                fontFamily: "Zoho Puvi",
                fontSize: "25px",
                textAlign: "center",
                marginTop: "2%",
                fontWeight: "bold",
              }}
            >
              <span style={{ display: "block" }}>
                RegTech re-engineered with “Software 3.0”
              </span>
              <span style={{ display: "block" }}>
                Built with Foundation Models, Gen AI, Machine Learning, NLP
              </span>
            </div>
            <div className="col-12 singleCardContainer" style={{}}>
              <div className="cardWrapper">
                <div className="col-4">
                  <img src={assistant} alt="Logo" loading="lazy" />
                  <h1>Assistant</h1>
                  <span className="cardText">
                    As a knowledge-assistant helps find information needed from
                    a database of regulatory documents like acts, rules,
                    regulations, master circulars, circular and notifications.
                    The interface is chat based and the answers are supported by
                    references.
                  </span>
                </div>
                <div className="col-4">
                  <img src={consultant} alt="Logo" loading="lazy" />
                  <h1>Consultant</h1>
                  <span className="cardText">
                    The AI assists with, Alerts on impactful regulatory changes,
                    Enlist the actions to be taken along with deadlines, Explain
                    the regulatory change in the notification, Build a knowledge
                    graph of the ever-changing rules to enable querying for
                    applicability, impact, timelines of the rules
                  </span>
                </div>
                <div className="col-4">
                  <img src={analyst} alt="Logo" loading="lazy" />
                  <h1>Analyst</h1>
                  <span className="cardText">
                    Help analyze the numbers periodically published by
                    regulatory bodies like NAVs, indexes etc in a natural
                    language interface. The system can generate plots and human
                    readable summaries in addition to tabular output.
                  </span>
                </div>
              </div>
            </div>
            {/* <div className="col-12 singleCardContainer" style={{}}>
          <div className="cardWrapper">
            <div className="custom-cardd">
              <img src={assistant} alt="Logo" />
              <h1>Assistant</h1>
              <span className="cardText">
              Find info from regulatory documents with chat
              </span>
            </div>
            <div className="custom-cardd">
              <img src={consultant} alt="Logo" />
              <h1>consultant</h1>
              <span className="cardText">
              Query and plot the indexes
              </span>
            </div>
            <div className="custom-cardd">
              <img src={analyst} alt="Logo" />
              <h1>Analyst</h1>
              <span className="cardText">
              Explain the regulatory changes
              </span>
            </div>
            <div className="custom-cardd">
              <img src={tutor} alt="Logo" />
              <h1>Tutor</h1>
              <span className="cardText">
              Learn with quizzes
              </span>
            </div>
          </div>
        </div> */}
            <div className="col-12 backgroundCurve">
              <div className="nextText">
                <span>Ready to take the next step?</span>
                <br />
                <span>Explore complieaze now.</span>
                <br />
              </div>
              <button
                className="demo-buttonFeature"
                onClick={() => handleDemoButtonClick()}
              >
                Get Started
              </button>
            </div>
            <div
              style={{
                textAlign: "center",
                fontSize: "40px",
                fontWeight: "bold",
                fontFamily: "Zoho Puvi",
                marginTop: "4%",
              }}
            >
              <span>Not just a documents aggregator</span>
              <br />
              <span style={{ color: "#4FC2C9" }}>Not just a chatbot</span>
              <br />
            </div>
            <div style={{ textAlign: "center", marginTop: "20px" }}
              ref={videoRef}>
              <video
                width="850"
                height="500"
                autoPlay
                loop
                muted>
                <source
                  src={process.env.PUBLIC_URL + "/Demo (1).mp4"}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
            <div
              style={{
                textAlign: "center",
                fontSize: "40px",
                fontWeight: "600",
                fontFamily: "Zoho Puvi",
                marginTop: "6%",
              }}
            >
              <span className="complexColor" style={{ display: "block" }}>
                Brings back the human element in the dry and
              </span>
              <span className="complexColor" style={{ display: "block", color: "black" }}>
                mechanical process of regulatory compliance
              </span>
            </div>
            <div className="flip-cards-container">

              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <img src={ecosystem} alt="Image 1" />
                    <h3>Integrates with your AWS Ecosystem</h3>
                  </div>
                  <div className="flip-card-back">
                    <p>Easy and effortless Integration to your AWS workflow.
                    </p>
                  </div>
                </div>
              </div>


              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <img src={custom} alt="Image 2" />
                    <h3>Customizability</h3>
                  </div>
                  <div className="flip-card-back">
                    <p>Your Workflows, Business Domains & Use case specific customizable</p>
                  </div>
                </div>
              </div>


              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <img src={navigation} alt="Navigation" loading="lazy" />
                    <h3>Easy files Navigation</h3>
                  </div>
                  <div className="flip-card-back">
                    <p>
                      File navigation made easy by Knowledge graph, Text
                      Similarity.
                    </p>
                  </div>
                </div>
              </div>

              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <img src={guard} alt="Guard" loading="lazy" />
                    <h3>Guardrails</h3>
                  </div>
                  <div className="flip-card-back">
                    <p>Your Privacy, Security gets guardrail through out</p>
                  </div>
                </div>
              </div>
            </div>

            {/* <div
          style={{
            textAlign: "center",
            fontSize: "40px",
            fontWeight: "bold",
            fontFamily: "Zoho Puvi",
            marginTop: "4%",
          }}
        >
          <span>complieaze is built by data experts</span>
          <br />
          <span>at dataeaze systems</span>
          <br />
        </div> */}
            {/* <div class="col-12 contentWrapper">
          <div className="card-container col-9">
            {cardData.map((card) => (
              <div
                key={card.id}
                className={`feature-card ${
                  card.id === activeCard ? "active" : ""
                }`}
                onClick={() => handleCardClick(card.id)}
              >
                <div className="card-content col-3">
                  <h2>{card.title}</h2>
                  {card.id === activeCard && <p>{card.content}</p>}
                </div>
              </div>
            ))}
          </div>
          <div className="card-image col-3">
            {cardData.map((card) => (
              <div
                key={card.id}
                className={`feature-card-image ${
                  card.id === activeCard ? "active" : ""
                }`}
              >
                {card.id === activeCard && (
                  <img src={card.image} alt={card.title} />
                )}
              </div>
            ))}
          </div>
        </div> */}
          </section>
          <div
            style={{
              textAlign: "center",
              fontSize: "40px",
              fontWeight: "bold",
              fontFamily: "Zoho Puvi",
              marginTop: "4%",
              marginBottom: "-55px",
            }}
          >
            <span>
              Complieaze is built by AI/ML experts empowering industry since
              2015
            </span>
          </div>
          <div className=" contentWrapper">
            <div className="card-container">
              {cardData.map((card) => (
                <div
                  key={card.id}
                  className={`feature-card ${card.id === activeCard ? "active" : ""
                    }`}
                  onClick={() => handleCardClick(card.id)}
                >
                  <div className="card-content ">
                    <h2>{card.title}</h2>
                    {card.id === activeCard && <p>{card.content}</p>}
                  </div>
                </div>
              ))}
            </div>
            <div className="card-image ">
              {cardData.map((card) => (
                <div
                  key={card.id}
                  className={`feature-card-image ${card.id === activeCard ? "active" : ""
                    }`}
                >
                  {card.id === activeCard && (
                    <img src={card.image} alt={card.title} loading="lazy" />
                  )}
                </div>
              ))}
            </div>
          </div>
          {/* <div className=" singleCardContainerr" style={{}}>
        <div className="cardWrapperr">
            <div className="custom-card">
              <img src={detection} alt="Logo" />
              <h3>Deep Learning Powered Intrusion Detection</h3>
              <span className="cardText">
              Detecting events like intrusions with computer vision and deep learning applied to fiber optics signals.
              </span>
            </div>
            <div className="custom-card">
              <img src={telecom} alt="Logo" />
              <h3>Machine Learning for Telecom</h3>
              <span className="cardText">
              Detecting events like intrusions with computer vision and deep learning applied to fiber optics signals.
              </span>
            </div>
            <div className="custom-card">
              <img src={security} alt="Logo" />
              <h3>Computer Vision for security of personnel</h3>
              <span className="cardText">
              AI on edge solution for security with person reidentification, multi camera tracking, real time alerting on security incidents.
              </span>
            </div>
            <div className="custom-card">
              <img src={safety} alt="Logo" />
              <h3>Computer Vision for worker safety</h3>
              <span className="cardText">
              Computer vision based application tailored for industrial safety - monitoring danger zones, security compliance, alerting on accident prone objects like forklifts.
              </span>
            </div>
            <div className="custom-card">
              <img src={geospatial} alt="Logo" />
              <h3>Deep Learning for geospatial imagery</h3>
              <span className="cardText">
              Deep learning for geo-spatial data for applications like accident risk prediction, encroachment detection using geo-spatial data.
              </span>
            </div>
          </div>
          </div> */}
          <section id="pricing" className="pricing-section">
            <div className="col-12 pricingBackgroundcurve">
              <div style={{ paddingTop: "100px" }}>
                <span>We have custom solution as per </span>
                <br />
                <span>your needs.</span>
                <br />
              </div>
            </div>
            <div className="pricing-cards">
              <div className="pricing-card basic">
                <h2>Basic</h2>
                <hr className="header-line"></hr>
                <div className="content">
                  <span>Web hosting on AWS </span>
                  <br />
                  <span>platform, with LLMs</span>
                  <br />
                  <span>from AWS Bedrock </span>
                  <br />
                  <span>like Claude</span>
                  {/* <br />
                  <br />
                  <br /> */}
                  <div className="hrLine-below"></div>
                  {/* <span>0.24 USD per 1K tokens</span>
                  <br />
                  <span style={{ textAlign: "center" }}>OR</span>
                  <br />
                  <span>0.00390 USD per request per </span>
                  <br />
                  <span>second</span> */}
                </div>
              </div>

              <div className="pricing-card premium">
                <h2>Premium</h2>
                <hr className="header-line"></hr>
                <div className="content">
                  <span>Self-hosted deployment in </span>
                  <br />
                  <span> AWS public or private cloud,</span>
                  <br />
                  <span> with "RegLLM", an open LLM </span>
                  <br />
                  <span>adapted and fine tuned </span>
                  <br />
                  <span>for regulatory compliance</span>
                  <div className="hrLine-below"></div>
                  {/* <span>0.96 USD per 1K tokens</span>
                  <br />
                  <span>OR</span>
                  <br />
                  <span>0.01560 USD per request</span>
                  <br />
                  <span>per second</span> */}
                </div>
              </div>

              <div className="pricing-card enterprise">
                <h2>Enterprise</h2>
                <hr className="header-line"></hr>
                <div>
                  <span>"RegLLM" model can be </span>
                  <br />
                  <span>adapted to work on </span>
                  <br />
                  <span>enterprise private data and</span>
                  <br />
                  <span> fine tuned as per your specific</span>
                  <br />
                  <span> Customisation to use cases </span>
                  <br />
                  <span>business use cases.</span>
                  <div className="hrLine-below"></div>
                  {/* <span>Contact sales</span> */}
                </div>
              </div>
            </div>
          </section>
          <section id="contact" className="contact-section">
            <div
              style={{
                textAlign: "center",
                fontSize: "40px",
                fontWeight: "bold",
                fontFamily: "Zoho Puvi",
                marginTop: "4%",
                marginBottom: "-25px",
              }}
            >
              <span style={{ marginBottom: "10px" }}>Get complieaze demo</span>
              <br />
              <div
                style={{
                  fontSize: "14px",
                  fontFamily: "Zoho Puvi",
                  fontWeight: "lighter",
                  marginTop: "15px",
                }}
              >
                <span>
                  Fill out these details and we will get right back to you.
                </span>
                <br />
                <span>We are available 24/5, Monday - Friday</span>
              </div>
              <br />
            </div>

            <div className="contact-form">
              <form
                onSubmit={handleFormSubmit}
                style={{ backgroundColor: "#f7faff" }}
              >
                <div className="form-input" style={{ width: "100%" }}>
                  <input
                    type="text"
                    placeholder="Your Name*"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div
                  className="form-input"
                  style={{ width: "calc(50% - 18px)" }}
                >
                  <input
                    type="text"
                    placeholder="Contact Number*"
                    name="contactNumber"
                    value={formData.contactNumber}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-input">
                  <input
                    type="text"
                    placeholder="Email ID*"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div
                  className="form-input"
                  style={{ width: "calc(33% - 35px)" }}
                >
                  <input
                    type="text"
                    placeholder="Company Name*"
                    required
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleInputChange}
                  />
                </div>
                <div
                  className="form-input"
                  style={{ width: "calc(33% - 30px)" }}
                >
                  <input
                    type="text"
                    placeholder="Select Country"
                    name="country"
                    value={formData.country}
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Looking For*"
                    required
                    name="lookingFor"
                    value={formData.lookingFor}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-input full-width">
                  <textarea
                    placeholder="Your Message"
                    style={{ height: "100%" }}
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                  ></textarea>
                </div>
                <div className="submit-text">
                  <label htmlFor="agree">
                    <input type="checkbox" id="agree" name="agree" required />
                    By clicking submit you agree to our
                    <span style={{ color: "#4FC2C9" }}> Privacy Policy.</span>
                  </label>
                </div>
                <button type="submit" className="submit-button">
                  Contact Now
                </button>
              </form>
            </div>
          </section>
          <Footer />
        </>
      )}
    </div>
  );
}

export default LandingPage;
