
import React, { useState } from "react";
import "./Navbar.css";
import logoImage from "../../assets/banereazeIcon.jpg";
import { useMediaQuery } from "react-responsive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faTimes,
  faHome,
  faDollarSign,
  faEnvelope,
  faStar,
} from "@fortawesome/free-solid-svg-icons";

function NavigationLinks({
  scrollToSection,
  closeSidebar,
  handleDemoButtonClick,
}) {
  const handleLinkClick = (sectionId) => {
    scrollToSection(sectionId);
    closeSidebar();
  };

  return (
    <nav className="navbar">
      <div
        style={{
          position: "absolute",
          left: "2%",
          fontSize: "50px",
          fontWeight: "bold",
        }}
      >
        <span style={{ color: "#4FC2C9" }}>Compli</span>e<span style={{ color: "#000" }}>aze</span>
      </div>
      <div className="nav-name">
        <div className="nav-link" onClick={() => handleLinkClick("home")}>
          Home
        </div>
        <div className="nav-link" onClick={() => handleLinkClick("feature")}>
          Feature
        </div>
        <div className="nav-link" onClick={() => handleLinkClick("pricing")}>
          Pricing
        </div>
        <div className="nav-link" onClick={() => handleLinkClick("contact")}>
          Contact Us
        </div>
      </div>
      <button className="demo-button" onClick={handleDemoButtonClick}>
        Get a Demo
      </button>
    </nav>
  );
}

function Sidebar({ isOpen, closeSidebar, scrollToSection }) {
  const handleLinkClick = (sectionId) => {
    scrollToSection(sectionId);
    closeSidebar();
  };

  return (
    <div className={`sidebar ${isOpen ? "open" : ""}`}>
      <div className="nav-name">
        <div className="nav-link" onClick={() => handleLinkClick("home")}>
          Home
        </div>
        <div className="nav-link" onClick={() => handleLinkClick("feature")}>
          Feature
        </div>
        <div className="nav-link" onClick={() => handleLinkClick("pricing")}>
          Pricing
        </div>
        <div className="nav-link" onClick={() => handleLinkClick("contact")}>
          Contact Us
        </div>
      </div>
    </div>
  );
}

function NavbarComponent() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handleDemoButtonClick = () => {
    window.location.href = "/google/login";
  };

  return (
    <div>
      {isMobile ? (
        <div className="navbar">
          <div
            style={{
              position: "absolute",
              left: "2%",
              fontSize: "50px",
              fontWeight: "bold",
            }}
          >
            <span style={{ color: "#4FC2C9" }}>Compli</span>e<span style={{ color: "#000" }}>aze</span>
          </div>
          <button className="demo-button" onClick={handleDemoButtonClick}>
            Get a Demo
          </button>
          <button className="breadcrumb-toggle" onClick={toggleSidebar}>
            {isSidebarOpen ? (
              <FontAwesomeIcon icon={faTimes} />
            ) : (
              <FontAwesomeIcon icon={faBars} />
            )}
          </button>
          {isSidebarOpen && (
            <Sidebar
              isOpen={isSidebarOpen}
              closeSidebar={() => setSidebarOpen(false)}
              scrollToSection={scrollToSection}
            />
          )}
        </div>
      ) : (
        <NavigationLinks
          scrollToSection={scrollToSection}
          closeSidebar={() => { }}
          handleDemoButtonClick={handleDemoButtonClick}
        />
      )}
    </div>
  );
}

export default NavbarComponent;